export const locale = {
  lang: "kk",
  data: {
    Menu: {
      Spravochnik: {
        Title: "Spravochniklar",
        Children: {
          Departments: "Filial bólimleri",
          Branches: "COATO filiallari",
          TicketTypes: "Shaqırıq túrleri",
          TicketStatuses: "Jaǵday túrleri",
          TicketPriorities: "Dáreje túrleri",
          Activities: "Iskerlik túrleri",
          Boards: "Shaqırıwlar",
          Projects: "Joybarlar",
        },
      },
      Home: "Bas bet",
      CRM: "Basqarıw paneli",
      HelpDesk: "Járdem aynası",
      GeoMap: "Geoxarita",
      GeoMapSetting: "Geoxarita sazlamalari",
      CallCenter: "Call oray",
      ChatService: "Chat xızmeti",
      Statistics: "Statistika",
      Consumers: "Qarıydarlar",
      All: "Ulıwma",
      LegalConsumers: "Yuridikalıq Qarıydarlar",
      LegalConsumer: "Yuridikalıq Qarıydar",
      PhysicalConsumers: "Xojalıq Qarıydarlar",
      PhysicalConsumer: "Xojalıq Qarıydar",
      VIPCustomers: "VIP klientler",
      BlockContacts: "Qara dizim",
      VIPCustomer: "VIP klient",
      Users: "Xızmetkerler",
      Operators:"Operatorlar",
      Management: "Basqarıw",
      Appeals: "Shaqırıwlar",
      Marketing: "Marketing",
      Roles: "Rollar",
      Services: "Xızmetler",
      Calls: "Qońırawlar",
      CallsHistory: "Tariyx",
      CallForward: "Baǵdarlanǵan",
      LiveCalls: "Janlı",
      QueueCalls: "Náwbettegi",
      Evaluation: "Baholaw",
      SendNotificatin: "Bildiriw xatı jiberiw",
      SendNotificationToClients: "Klientlerge jiberiw",
      SendNotificationToOperators: "Operatorlarǵa jiberiw",
      AssignRoles: "Rol biriktiriw",
      RoleNotMessage: 'Rol joq!',
      CallRecording: "Jazıp alınǵanlar",
      PersonnelManagement: "Xızmetkerler basqarıwi",
      viewContract: "Shártnamanı kóriw",
      UserCode: "Paydalanıwshı kodi",
      ConsumerCode: "Qarıydar kodi",
      ContractNumber: "Shártnama nomeri",
      FullNameofConsumer: "Qarıydardıń tolıq atı",
      ShortNameofConsumer: "Qarıydardıń qısqasha atı",
      INN: "INN",
      Account: "Esap",
      ContractDate: "Shártnama sánesi",
      StatisticalData: "Statistikalıq maǵlıwmatlar",
      AddressAndBankDetails: "Mánzil hám bank rekvizitlari",
      ConditionsofEnergy: "Energiya támiynatı shártleri",
      PowerandAmount: "Quwat hám energiya muǵdarı",
      OtherInfo: "Basqa maǵlıwmatlar",
      SubtractedFrom: "Ajıratıldı",
      ListMeter: "Esaplagichlar dizimi",
      ControlCode: "Basqarıw kodi",
      OKPO: "OKPO",
      Subgroup: "SOOGU kishi toparı",
      Codeby: "OKONH boyınsha kod",
      FormCodeby: "FSC boyınsha forma kodi",
      FormCodeby1: "COPF boyınsha forma kodi",
      PlaceofRegistration: "Dizimnen ótkel",
      UserMonitoring : "Xızmetkerler kestesi",
      Tickets: "Shaqırıwlar",
      Contracts: "Shártnamalar",
      Reports: "Esabatlar",
      Report: "Esabat",
      RequestsReports: "Aymaqlar",
      StatisticalReports: "Shaqırıq túrleri",
      TimeReports: "waqıt boyınsha",
      OperatorReports: "Operatorlar boyınsha",
      Main: "Tiykarǵı",
      Prefiks:"Prefiks",
      Incidents: "Insidentlar",
      BotReport:"Bot esabatı",
      Spy:"Shpionaj",
      SipNumbers:"SIP nomerler",
      BreakTime: 'Tánepis waqıtlar ',
      WorkTime: 'Jumıs waqıtlar',
      CallTime:"Qo'ngiroqlar arasındaǵı waqıt",
      NotAnsweredCall:"Juwapsız qońırawlar",
      List: "Dizim",
      BreakTimes: 'Tánepis waqıtlari',
      WorkTimes: "Jumıs waqıtlari",
      ReleAudit:"Rele Audit",
      LiveStats:"Grafik",
      OperatorReport:"Operatorlar Esabatı",
      CoatoSettings: "COATO ruhsatlari",
      CoatoSettingsTitle: "Operatorlardı COATOga salıstırǵanda kirisiw huqıqları",
      CommonReport:"Ulıwma Esabat",
      PhoneChangeAudit:"Telefon nomerler esabatı",
      DeleteChannels:"Qońıraw kanallardı óshiriw",
      VideoStreamMonitoring:"Xızmetkerler video gúzetiwi"
    },

    Block: {
      Active: "Aktiv",
      InActive: "Nofaol",
    },
    Time:{
      Hour:"Saat",
      Minute:"Minuta",
      Second:"Sekund"
    },

    Actions: {
      Buttons: {
        Submit: "Saqlaw",
        Cancel: "Bıykarlaw",
        Open: "Ashıw",
        LoadMore:"Kóbirek júkleń",
        Forward: "Uzatıw",
        WorkBind:"Jumıs waqtı biriktiriw"
      },
      Toast: {
        Massage: "Óshiriwdi tastıyıqlaw",
        ToastDelete: "Saylanǵan keste qatarı tabıslı óshirildi!",
        ConfirmMassage: "Dıqqat óshiriwdi tastıyıqlang!",
        ConfirmButton: "Óshiriw",
        CancelButton: "Bıykarlaw",
        Yes: "Awa",
        No: "Joq",
      },
      Title: "Háreket",
      Add: "Qosıw",
      AddTicket: "Shaqırıq qosıw",
      Cleaning: "Tazalaw",
      SendNotification: "Bildiriw xatı jiberiw",
      Update: "Redaktorlaw",
      UpdateCallData: "Qońıraw tolıq maǵlıwmatların jańalaw",
      Delete: "Óshiriw",
      DeleteImg: "Rostan da bul súwretti óshiriwdi qalelaysizmi?",
      DeleteImgMessage: "Súwret óshirildi!",
      DeleteIncidentsWarning: "Rostan da bul insidentni óshiriwdi qalelaysizmi?",
      DeleteIncidentsMessage: "Insident maǵlıwmatları óshirildi!",
      UpdateIncidentsTitle: "Redaktorlawdı tastıyıqlaw",
      UpdateIncidentsWarning: "Rostan da bul insidentni redaktorlawdı qalelaysizmi?",
      UpdateIncidentsMessage: "Insident maǵlıwmatları tahrirlandi!",
      ForwardCall: " Qońırawdı uzatıw"
    },

    Dashboard: {
      Statistics: "Statistika",
      AppealOfNumbers: "Shaqırıwlar sanı aymaqlar kesiminde",
      CallOfNumbers: "Qońırawlar sanı aymaqlar kesiminde",
      AppealOfStatus: "Shaqırıwlar jaǵdayı statistikası",
      AppealOfModuleType: "Shaqırıwlar qay jerden jaratılǵanlıǵı boyınsha statistika",
      AppealOfTypeByRegions: "Shaqırıwlar túrleri boyınsha statistika aymaqlar kesiminde",
      Done: "Orınlanǵan",
      InProgress: "Processda",
      Checked: "Tekserilip atır",
      Backlog: "Úyrenilip atır",
      Archived: "Arxivlanǵan",
      AppealsOfMonths: "Shaqırıwlar sanı aylar kesiminde",
      Appeals: "Shaqırıwlar",
      GradeByUser: "Paydalanıwshılar bergen bahalar",
      Users: "Paydalanıwshılar",
      Excellent: "Ájayıp",
      Good: "Jaqsı",
      Satisfactory: "Qanaatlanǵan",
      NotSatisfactory: "Qaniqarsiz",
      Bad: "Jaman",
      AppealOfType: "Shaqırıwlar túrleri boyınsha statistika",
      Complaints: "Shaǵımlar",
      Suggestions: "Usınıslar",
      MustBeDone: "Orınlanıwı kerek",
      Completed: "Juwmaqlandi",
      DDone: "Atqarıldi",
    },

    Reports: {
      SubName: {
        Time: "Qońıraw dawam etiw waqti boyınsha",
        Region: "wálayat boyınsha",
        Operator : "Operatorlar boyınsha",
      },
      Requests: "Shaqırıwlar",
      Statistical: "Statistika",
      Users: "Xızmetkerler",
      Tickets: "Shaqırıwlar",
      Time: "Qońıraw dawam etiw waqti",
      Region: "Wálayatlar",
      Operator : "Operatorlar",
    },

    Table: {
      Pagination: "{{totalPages}} den {{starterItemNumber}} den {{endItemNumber}} gacha",
      HeaderNumbers: "№",
      PaginationShow: "Kórsetiw",
      NoData: "Maǵlıwmat tabilǵan zatdı!",
    },

    Components: {
      Users: {
        Title: "Xızmetkerler",
        TableHeader: {
          Name: "At",
          Email: "Email",
          EmailverifiedAt: "Email tastıyıqlanǵan waqtı",
          CreatedAt: "Jaratılǵan waqtı",
          UpdatedAt: "Ózgertirilgen waqtı",
          CoatoBranch: "COATO filial",
          CoatoDepartment: "COATO bólim",
          SipNumber: "SIP nomeri",
          Phone: "Telefon",
          Address: "Mánzil",
          Status: "Jaǵday",
          ACTIVE: "Aktiv",
          IN_ACTIVE: "Aktiv emes",
          PERMISSION: "Ruhsat etilgen",
          FORBIDDIN: "Qadaǵan etilgen",
          LocalPassword: "Lokal parol",
          LocalPassword2: "Lokal paroldı tastıyıqlang",
          ConfirmLocalPassword: "Qosımshanı ashıw ushın lokal paroldı kiritiń",
          Staff:"Xızmetker",

        },
      },
      Consumers: {
        LegalConsumers: {
          Title: "Yuridikalıq qarıydarlar",
          TableHeader: {
            ConsumerPersonalAccount: "Qarıydar kodı",
            ConsumerBusinessName: "Qarıydar atı",
            AddressNote: "Mánzil",
            BookCode: "Kitap",
            AccountingStatus: "Esaplaw jaǵdayı",
            CalculationCategory: "Esaplaw túri",
            ReportStatus: "Esabat jaǵdayı",
            ConsumerStatus: "Qarıydar jaǵdayı",
            MetersCount: "Esaplagichlar sanı",
            ActionColumn: "Háreketler",
          },
          DateConclusionOfAgreement: "Shártnama dúzilgen sáne",
          ContractNumber: "Shártnama nomeri",
          ContracterFullName: "Tolıq qarıydar",
        },
        LegalConsumersById: {
          ConsumerPersonalAccount: "Qarıydar kodi",
          SummaryDate: "Juwmaq sánesi",
          ContractNumber: "Shártnama nomeri",
          ContractRenewalDate: "Shártnamanı uzaytırıw sánesi",
          CustomerFullName: "Qarıydardıń tolıq atı",
          CustomerShortName: "Qarıydardıń qısqasha atı",
          FaceType: "Júz túri",
          INN: "INN",
          NDS_RegistrationCode: "NDS dizimnen ótiw kodi",
          Score: "Esap",
          PromNepromPred: "Prom/Neprom/Pred",
          Resident: "Rezident",
          ResidentYes: "Awa",
          ResidentNo: "Joq",
          CadastreAddress: "Kadastr boyınsha mánzil",
          CadastreCode: "Kadastr kodı",
          Sector: "Sektor",
          PINFL: "PINFL",
          StatisticalInformation: {
            Title: "Statistikalıq Maǵlıwmatlar",
            PaymentCode: "Tólew kodı",
            OKPO: "OKPO",
            SOOGU: "SOOGU",
            SOOGU_Subgroup: "SOOGU kisi toparı",
            OKED_Code: "OKED kodı",
            FormCode_KFS: "KKF boyınsha forma kodı",
            FormCode_COPF: "COPF forma kodı",
            PlaceOfRegistration: "Dizimnen ótkel",
            ConsumerGroup: "Qarıydar toparı",
          },
          Address: {
            Title: "Mánzil",
            SOATO_Address: "SOATO mánzilleri",
            Neighborhood: "Máhelle",
            Street: "Kóshe",
            Home: "Úy",
            Home2: "Úy(Dom)",
            FlatName: "Kvartira",
            CorpusName: "Korpus",
            LegalAddress_SOATO: "SOATO yuridikalıq mánzil",
            HigherOrganization: "Joqarı shólkem",
            ContactPhoneNumber: "Baylanısıw telefon nomeri",
            MobilePhone: "Mobil telefon",
            EmailAddess: "Elektron pochta adresi",
          },
          BankDetails: {
            Title: "Bank rekvizitlari",
            CalculationType: "Esaplaw túri",
            MFO: "MFO",
            Score: "Esap",
            BranchCode: "Filial kodi",
            BranchRecruitment: "Filialdı jallaw",
            Supervisor: "Qadaǵalawshı",
            Accountant: "Buxgalter",
            FIO: "FIO",
            Position: "Lawazım",
          },
        },
        PhysicalConsumers: {
          Title: "Fizikalıq qarıydarlar",
          TableHeader: {
            PersonalAccount: "Jeke esap",
            Name: "FIO qarıydarsı",
            Passport: "Passport",
            Address: "Mánzil",
            Agent: "Agent",
            TP: "TP",
            Transformer: "Azıqlantiruvchi",
            CalculationType: "Esaplaw turi",
            CalculationStatus: "Jaǵday",
            LastReading: "Aqırǵı aylanba jol",
            LastReadingDate: "Aqırǵı aylanba jol sánesi",
          },
        },
        PhysicalConsumersById: {
          FaceCard: {
            Title: "Qarıydar kartası",
            PersonalAccount: "Jeke esap",
            MobilePhone: "Mobil telefon",
            Address: "Mánzil",
            Neighborhood: "Máhelle",
            StreetAddress: "Úy adresi",
            Surname: "Famılıya",
            FirstName: "At",
            FatherName: "Sharıf",
            Reminder: "Esletpe",
          },
          Document: {
            Title: "Hújjet",
            DocumentType: "Hújjet túri",
            IdCard: "ID karta",
            Passport: "Passport",
            DocumentSeries: "Hújjet ceriyasi hám №",
            GivenWhom: "Kim tárepinen berilgen",
            DateIssue: "Berilgen sáne",
            DateBirth: "Tuwılǵan sana",
            INN: "INN",
            CadastralCode: "Kadastr kodı",
            PINFL: "PINFL",
            Language: "Til",
          },
          GeneralInformation: {
            Title: "Hámme maǵlıwmatlar",
            Telephone: "Telefon",
            Agent: "Agent",
            Book: "Kitap",
            Brigade: "Brigada",
            Status: "Jaǵday",
            StatusAlive: "Tiri",
            StatusDead: "Ólgen",
            Consent: "Razılıq",
            ConsentYes: "Awa",
            ConsentNo: "Joq",
          },
          Counter: {
            Title: "Esaplaǵısh",
            CounterType: "Esaplaǵısh túri",
            SerialMeterNumber: "Esaplagishni ceriya nomeri",
            SerialNumber: "Ceriya nomeri",
            NoStandartSeals: "Uzstandart móhirler №",
            DateUzstandartSeal: "Uzstandart muhrining sánesi",
            EnsbytSeals: "Ensbyt móhirler №",
            DateEnsbytSeals: "Ensbyt muhrining sánesi",
            BPI_No: "BPI móhir №",
            BPI_Date: "BPI móhir sánesi",
            MeterInstallationLocation: "Ornatıw jayi",
            ASKUE_Type: "ASKUE turi",
            AgreementDate: "Shártlesiw sánesi",
            ASKUE_Connect: "ASKUE ga jalǵanıw",
            ASKUE_ConnectYes: "Awa",
            ASKUE_ConnectNo: "Joq",
            CounterStatus: "Esaplaǵısh jaǵdayı",
            BitPower: "Bıyt kólemi",
            TransformerCoefficient: "Transformaciya qatnası",
            SolarPanel: "Quyash paneli",
            TariffPrice: "Tariyp muǵdarı",
            installationReadingPlus: "Ornatıw waqtındaǵı kórsetkishler (+A)",
            installationReadingMinus: "Ornatıw waqtındaǵı kórsetkishler (-A)",
            PreviousIndications: "Dáslepki oqıwlar| A|",
            InstallationDate: "Ornatıw sánesi",
          },
          Certificate: {
            Title: "Sertifikat",
            No: "Sertifikat №",
            Check: "Tekseriw",
            ExpirationDate: "Ámel qılıw",
          },
          ConstructionInformation: {
            Title: "Dúzılıw haqqında ma\'lumot",
            Substation: "Podstansiya",
            Nourishing: "Azıqlantiruvchi",
            Transformer: "Transformator",
          },
          Agreement: {
            Title: "Shártnama",
            TariffSum: "Tariyp (sum)",
            TariffCode: "Tariyp kodi",
            TariffName: "Tariyp ati",
            ContractNumber: "Shártnama nomeri",
            DateApplication: "Arza sánesi",
            TariffChangeDate: "Tariypni ózgertish sánesi",
            DateConclusionContract: "Shártnama dúzilgen sane",
            ContractRenewalDate: "Shártnamanı uzaytırıw sánesi",
            TariffImplementationDate: "Ta\'rif engizilgen sana",
            SignUp_No_REP: "Dizimnen ótiw № hám REP",
          },
          AdditionalInformation: {
            Title: "Qosımsha maǵlıwmatlar (Úlgili shártnama boyınsha vII bólim)",
            LEP: "LEP",
            One_TP: "1 Azıqlantıratuǵın + TP",
            Two_TP: "2 Azıqlantıratuǵın + TP",
            TypeAccommodation: "Turar jay turi",
            ClassResidence: "Turaq-jay qatlamı",
            ApartmentGassed: "Kvartira gazlawtirilganmi",
            ApartmentGassedYes: "Awa",
            ApartmentGassedNo: "Joq",
            ConnectedCentralSystem: "Oraylıq qizdırıw sistemasına jalǵanǵanmi",
            ConnectedCentralSystemYes: "Awa",
            ConnectedCentralSystemNo: "Joq",
          },
          ConsumerAccess_LEP: {
            Title: "Qarıydar 2 x yamasa 3 x úshinshi tárep LEP-0, 4 kv",
            NumberLightingDevices: "Úy-úskeneni sanı (dana )",
            PowerLightingDevices: "Úy-úskeneni quwatı (kvt)",
            InstalledPower: "Jalǵanǵan quwat (kvt)",
            NumberSockets: "Rezidentlar muǵdarı (dana )",
            TotalPowerConsumption: "Ulıwma quwat tutınıwı (kvt)",
            TheNumberPersons: "Jasaw sanı (shaxslar )",
          },
          Contractvalue: {
            Title: "Shártnama ma`nisi (kvt/soat)",
            January: "Yanvar",
            February: "Fevral",
            March: "Mart",
            One_Kv: "1 kv",
            AnnualContract: "Jıllıq shártnama boyınsha (kvt/soat)",
            April: "Aprel",
            May: "May",
            June: "Iyun",
            Two_Kv: "2 kv",
            AnnualAmount: "Jıllıq muǵdar (sum)",
            July: "Iyul",
            August: "Avgust",
            September: "Sentyabr",
            Three_Kv: "3 kv",
            October: "Oktyabr",
            November: "Noyabr",
            December: "Dekabr",
            Four_Kv: "4 kv",
          },
        },
        VIPCustomers: {
          Search: "Telefon nomer",
          AllCustomers: "Barlıq klientler",
        },
        Consumers: {
          Title: "Qarıydarlar",
          TableHeader: {
            FirstName: "At",
            LastName: "Famılıya",
            MiddleName: "Sharıf",
            Phone: "Telefon nomer",
            CreatedAt: "Jaratılǵan waqtı",
          },
        },
        Children: {
          LegalConsumers: "Yuridikalıq iste\'molchilar",
          PhysicalConsumers: "Fizikalıq Qarıydarlar",
        },
        ConsumerModalTitle: "Qarıydardı maǵlıwmatları",
        Title: "Qarıydarlar",
      },
      Spravochniks: {
        SpravochniksBranches: {
          Title: "Filiallar",
          TableHeader: {
            Code: "Kod",
            Name: "At",
            Email: "Email",
            Status: "Jaǵdayı",
            CreatedAt: "Jaratılǵan waqtı",
            UpdatedAt: "Ózgertirilgen waqtı",
            CoatoBranch: "COATO filial",
            CoatoDepartment: "COATO bólim",
            Time: "waqtı",
          },
        },
        SpravochniksDepartments: {
          Title: "Bólimler",
          TableHeader: {
            Code: "Kod",
            CoatoCode: "COATO kod",
            Name: "Ism",
            Email: "Email",
            Status: "Jaǵdayı",
            CreatedAt: "Jaratılǵan waqtı",
            UpdatedAt: "Ózgertirilgen waqtı",
            CoatoBranch: "COATO filial",
            CoatoDepartment: "COATO bólim",
            CoatoBranchId: "COATO filial ID",
            CoatoBranchName: "COATO filial ati",
          },
        },
        Projects: {
          Title: "Joybarlar",
          TableHeader: {
            Name: "Ati",
            ShortDesk: "Qısqasha ati",
            CreatedAt: "Jaratılǵan waqtı",
            UpdatedAt: "Ózgertirilgen waqtı",
            Description: "Xarakteristika",
          },
        },
      },
      Dashboard: {
        SelectPeriod: "Dáwirdi saylań",
        SelectRegion: "Aymaqtı saylań",
        RequestType: "Soraw turi",
        RequestStatus: "Sorawlar jaǵdayı statistikası",
        AppealType: "Shaqırıwlar túrleri boyınsha statistika",
      },
      Tickets: {
        CoatoCode: "COATO kod",
        TableTitle: "Shaqırıwlar dizimi",
        Type: "Túri",
        Importance: "Zárúrligi",
        Status: "Jaǵdayı",
        Title: "Bas bet",
        Priority: "Dárejesi",
        DateSelect: "waqıt aralıqi",
        Degree: "Dárejesi",
        Performer: "Orınlawshı",
        Deadline: "Tawısıw waqtı",
        Archive: "Arxiv",
        Archived: "Arxivlangan",
        NotArchived: "Arxivlanǵan",
        Description: "Xarakteristika",
        File: "Fayl",
        Created: "Jaratılǵan waqtı",
        Updated: "Tahrirlangan waqtı",
        Creator: "Jaratıwshı",
        Where: "Jaratılıw adresi",
        FileDrag: "Fayldı bulmanǵa tartıń yaki",
        FileSelect: "fayldı saylań",
        MaxFile: "Maxsimal fayl jiberiw kólemi",
        Tabs: {
          Comments: "Túsindirmeler",
          History: "Tariyx",
          StatusHistory: "Jaǵday Tariyxı",
        },
        WriteComment: "Túsindirme jazıń",
        Edit: "Ózgertiw",
        Assigners: "Orınlawshılar",
        NotFound: "Maǵlıwmat tabilǵan zatdı!",
        Confirmation:{
          message:"Shaqırıq xolatini",
          from:"den",
          to:"ga",
          question:"ózgertiwdi qáleysizbe"
        },
        HasFile:{
          No:"Fayl joq",
          Yes:"Fayl ámeldegi"
        },
        EmpthyDescription:"Tolıq xarakteristika qo\'shing... ",
        Ticket:'Shaqırıq ',
        Create:"jaratıldı ",
        Update:"o'zgeritirildi",
        EmpthyValue:"Bos baha",
        CreateFileName:"Jaratılǵan fayl atı ",
        DeletedFileName:"Óshirilgen fayl atı ",
        AssignUser:"Biriktirilgen orınlawshı ",
        UnAssignUser:"Biykar etilgen orınlawshı "
      },
      CallCenter: {
        TableHeader: {
          QueueCalls: "Náwbettegiler",
          TableHistory: "Qońırawlar tariyxı",
          CallForward: "Baǵdarlanǵan qońırawlar",
          TableRecording: "Jazıp alınǵan qońırawlar",
          Type: "Túri",
          Status: "Jaǵdayı",
          From: "Kimdan",
          ToOperator: "Kimga",
          To: "Operator",
          Via: "Telefon",
          Tap: "Operator",
          via: "Telefon",
          CreatedAt: "Jaratılǵan waqtı",
          End: "Tamamlanılǵan waqtı",
          Time: "waqtı (minuta/soniya)",
          Audio : "Sáwbet jazıwi",
          ContactTime: "Baylanıs waqtı",
          WaitingTime: "Kútiw waqtı",
          TotalWaitingTime: "Ulıwma kútiw waqtı",
          TotalDurationTime: "Ulıwma baylanıs waqtı",
          CustomerPhoneNumber: "Klienttiń telefon nomeri",
          Language: "Til",
          Filters: "Filter",
          FromOperatorSip: "Yo'naltirgan operatordıń SIP nomeri",
          ToOperatorSip: "Qabıl etken operatordıń SIP nomeri",
          Incoming: "Kiretuǵın",
          Outgoing: "Shıǵıwshı",
          IncomingCalls: "Kiretuǵın qońırawlar",
          OutgoingCalls: "Shıǵıwshı qońırawlar",
          Answered: "Juwap berilgen",
          NotAnswered: "Juwap berilmegen",
          Missed:"Ótkerip jiberilgen",
          MissedCallCount:"Ótkerip jiberilganlar",
          HungUp:"Qoyıp jiberilgen",
          CallType: "Qońıraw túri",
          CallStatus: "Qońıraw jaǵdayı",
          MissedCall: "Juwapsız qońıraw nomeri",
          LastCall: "Aqırǵı qońıraw nomeri",
          Interval:"Interval",
          SipNumber:"Sip Nomer",
          IVRYes:"IVR den paydalanǵan tutınıwshılar ",
          IVRNo:"IvR den paydalanbaǵan tutınıwshılar ",
          IVR:"IVR",
          Call:"Qońıraw",
          AllTime:"Barlıq dáwirler",
          AllTimeSee:"Barlıq davrlarni kóriw",
          ViewEvaluation: "Qarıydar biriktirmasdan bahalawda kórinisi",
          AccountNumber: "Esap nomeri",
          ConsumerType: "Istemshi turi",
          HOUSEHOLD: "Xojalıq",
          LEGAL: "Yuridikalıq",
          ConsumersReport: "Qarıydarlar esabatı",
          LiveStreamingAllowed:"Video gúzetiw",
          VideoRecord:"Video jazıwlar",
          StartTime:"Baslanuw waqıtı",
          Link:"Silteme",
          Record:"Jazıwlar",
          UzbRusKk:"Uzbek| Orıs | Qaraqalpaq",
          DurationTotalAvg:"Dawam etiw waqti(Ulıwma|Ortasha)",
          WorkPercent:"Jumıs procenti"
        },
        TableUserID: {
          ReqType: {
            Title: "Arza turi",
            Request: "Soraw",
            Complaint: "Shaǵım",
            Offer: "Usınıs",
            Service: "Xizmet kórsetiw",
          },
          UserType: {
            Title: "Klient mámilesi",
            Well: "Jaqsı",
            Medium: "Ortasha",
            Rude: "Jaman",
            VeryBad: "Júdá jaman",
            Lose: "Adasıp túsken nomer",
          },
          UserData: "Klient maǵlıwmatları",
          Description: "Esletpe",
          SubmitBtn: "Jıberiw",
          CallHistory: "Qońırawlar tariyxı",
          UserAngryTitle: "Qızba",
        },
        Call: {
          CounterStatus: {
            Launch: "Jumısqa túsiriw",
            Loading: "Júklenip atır",
            On: "Qosılǵan",
            Off: "Óshirilgen",
            OnBtn: "Qosıw",
            OffBtn: "Óshiriw",
            CountersStatus: "Esaplagichlar",
            ChangeCountersStatus: "Esaplagichlar jaǵdayın ózgertiw",
            LaunchToastText: "Tabıslı jumısqa tusirildi",
            Error400Text: "Esaplagichni 10 minutada 1 ret qayta jumısqa túsiriw múmkin",
            AllBalance: "Jámi balans:"
        },
          UserSearch: "Qarıydar qıdırıw",
          UserInfo: "Qarıydar maǵlıwmatları",
          ConsumerType: "Qarıydar túri",
          ConsumerName: "Qarıydar maǵlıwmatı",
          HomePhone: "Úy telefonı",
          PassportNumber: "Passport nomeri",
          PassportSerial: "Hújjet ceriyasi hám №",
          TableHeader: {
            Phone: "Telefon nomeri",
            FISH: "Qarıydardıń tolıq atı",
            oneName: "Atı",
            Type: "Qarıydardıń túri",
            Fio: "F.I.O",
            Address: "Mánzil",
            PersonalAccount: "Jeke esap",
            CounterNumber: "Esaplaǵısh nomeri",
            CounterInformation: "Esaplaǵısh maǵlıwmatları",
            InterruptStartTime: "Úzilis baslanıw waqtı",
            InterruptEndTime: "Úzilis tawısıw waqtı",
          },
          SolveProblem: "Mashqalanı sheshiw ushın ótiń:",
          History: "Qońırawlar tariyxı",
          Status: {
            CounterStatus: "Esaplaǵısh jaǵdayı",
            StateBalance: "Balans jaǵdayı",
            OtherProblems: "Basqa máseleler",
            SubStatus: {
              Good: "Jaǵdayı jaqsı",
              Debt: "Qarız bar",
              Repair: "Remontlaw jumıslari",
              Bad:"Jaǵdayı jaman ",
              Unknown:"Belgisiz"
            },
          },
          FeedbackAboutClient: "Klient haqqında oy-órisler",
          TotalItemsSelected: "{ count } maǵlıwmat tańlandı",
          Incident: "Инцидент",
          All: "Jami",
          Electr: "Elektr",
          ActViolation: "Akt qaǵıyda -aynıwı",
          Fine: "Penya",
          Reconnection: "Qayta jalǵanıw",
          WithoutContract: "Shártnamasız tutınıw",
          Reactive: "Reaktiv",
          Bust: "Artıqsha",
          Shortage: "Kemshilik",
          Services: "Xızmetler",
        },
        Title: "Call Center",
        OperatorIsReady: "Juwap beriw!",
        WaitingClientsNotFound: "Kutayotgan klientler joq",
        CountOfClientsWaiting: "{{count}} ta klient operator juwap beriwin kútip atır",
        CallConnectedSuccessfully: "Baylanıs tabıslı ornatildi!",
        CallEnded: "Baylanıs tamamlanılǵan!",
        SocketConnected: "Server menen baylanıs tabıslı ornatildi!",
        SocketError: "Qátelik júz berdi. Ótinish, betti jańalang hám qayta urınıp kóriń.",
        SocketClosed: "Server menen baylanıs uzildi!",
        NotConnect: "Ulanmadi. Ótinish keyin qayta urınıp kóriń",
        ConfirmSpyCall: "Qońırawdı baqlawdı tastıyıqlaw",
        ConfirmSpyCallMessage: "Haqıyqattan da bul qońırawdı baqlawdı qáleysizbe?",
        WaitingConsumersCount: "Qońırawlar gezegi",
        CallIsNotAvailable: "Sizde qon`ıraw joq",
        AttachCallToConsumerSuccessfull: "Qońıraw klientke tabıslı biriktirildi",
        MobilePhoneEdited: "Mobil telefon tabıslı almastırildi",
        AttachCallToConsumer: "Qońırawdı biriktiriw",
        CallEvaluation: "Qońırawdı bahalaw",
        AttachCallToConsumerConfirm: "Haqıyqattan da ámeldegi qońırawdı bul qarıydarǵa biriktirmoqchimisiz?",
        UserEndedCall: "Qońıraw tugadi",
        MakeCall: "Qońıraw qılıw",
        ToastCalled: "Qońıraw ámelge asırıldı!",
        OutgoingCalls: "Shıǵıwshı qońıraw",
        OutgoingCall: "Shıǵıwshı",
        Operator: "Operator",
        ToAllOperators: " Barlıq operatorlarǵa",
        CallPrefix:{
          Region: "Aymaq",
          Prefix:"Prefiks"
       },
       SpyReport:{
        Title:"Shpionaj esabatı",
        TableHeader: {
          Name: "At",
          Email: "Email",
          CreatedAt: "Jaratılǵan waqtı",
          StartSpy:"Baslanıw waqtı",
          EndSpy:"Tawısıw waqtı "
        },

      },
      Attention:"Dıqqat",
      DeleteChannelTitle:"Barlıq qońıraw kanalların qayta jumısqa túsiriwdi ámelge asırıw ushın óshiriw tuymesin basıń.",
      Mention:"Esletpe",
      MentionAction:"Esletpe: Barlıq háreketlerińiz kuzatilib barıladı.",
      NoLiveVideo:"Janlı video joq",
      LiveVideoActive:"Janlı video aktiv",
      LiveVideoError:"Janlı video házirde aktiv emes. Ótinish, betti jańalang",
      LiveVideoServerError:"Janlı video uzatıwda server menen jalǵanıwda qátelik"
      },
      Boards: {
        title: "Shaqırıwlar",
        Filter: {
          Search: "Qıdırıw",
          IgnoreResolved: "Atqarılmaǵan wazıypalar",
          OnlyMyIssue: "Meniń wazıypalarim",
          ResetAll: "Tazalaw",
          User: "Paydalanıwshı",
        },
        TableHeader: {
          Backlog: "Úyrenilmoqda",
          Selected: "Processda",
          InProgress: "Tekserilmoqda",
          Done: "Atqarıldi",
        },
      },
      TicketTypes: {
        Title: "Shaqırıq túrleri",
        TableHeader: {
          Name: "Ati",
          NameUz: "Atı_Uz",
          NameRu: "Atı_Ru",
          NameKk: "Atı_Qq",
          NameCrl: "Atı_Crl",
          Color: "Reńi",
          Icon: "Belgi",
          Status: "Jaǵdayı",
        },
      },
      TicketStatus: {
        Title: "Jaǵday túrleri",
        TableHeader: {
          Name: "Ati",
          Color: "Reńi",
          Order: "Tártip",
          Status: "Jaǵdayı",
        },
      },
      TicketPriorities: {
        Title: "Dáreje túrleri",
        TableHeader: {
          Name: "Ati",
          Color: "Reńi",
          Status: "Jaǵdayı",
        },
      },
      Activities: {
        Title: "Iskerlik túrleri",
        TableHeader: {
          Name: "Ati",
          Description: "Xarakteristika",
          CreatedAt: "Jaratılǵan waqtı",
        },
      },
      Reports: {
        Excel: "Excel",
        TimeBy: {
          Title: "Qońıraw dawam etiw waqti boyınsha esapatlar",
          TableHeader: {
            Time: "waqıt",
            AllCall: "Barlıq qońırawlar",
            DoneCall: "Hal etilgen qońırawlar (sanı )",
            DonePercentage: "Hal etilgen qońırawlar (procent)",
          },
        },
        RegionBy: {
          Title: "wálayatlar boyınsha esapatlar",
        },
        OperatorBy: {
          Title: "Operator jumısı haqqında esabat",
        },
        Bot:{
          Title:"Botdan alınǵan esabatlar",
          TableHeader: {
            Name:   "Qarıydar maǵlıwmatı",
            Account_Type: "Qarıydar túri",
            Account_Number:"Akkount nomeri",
            Coato:"COATO",
            Count: "Sorawlar sanı",
            Last_Request:"Sońǵı soraw waqtı"
          },
       }
      },
      SipNumber:{
        Title:"SIP nomerler",
        TableHeader: {
          Phone:   "Nomer",
          Parol: "Parol",
          Status: "Jaǵdayı",
          CreatedAt: "Jaratılǵan waqtı",
        },
      },
      Operators: {
        BindWorkBreakTimeToOperators: 'Jumıs hám tánepis waqıtların biriktiriw'
      }
    },

    Errors: {
      Required: "Májburiy maydan",
      ErrorPastDate: "Qátelik, ótken sana",
      ErrorElapsedTime: "Ótken saat",
      ErrChooseFuture: "Keleshekti saylań",
      ErrorOfflineText: "Internetge ulaning",
      ErrorOfflineTitle: "Siz oflaynsiz. Baylanıstı teksering",
      MaxLengthError: "Maksimal uzınlıq : {{ value }}| Házir: {{ controlvalueLength }}",
    },

    ERROR_LIST: {
      POPUP_RESTRICTED: "Sizdiń brauzerińizde baspadan shıǵarıw betin ashıwǵa ruxsat berilmegen!",
      SERVER_ERROR: "Serverde qátelik júzege keldi!",
      ACCESS_DENIED: "Kirisiw sheklengen!",
      BAD_REQUEST: "Nadurıs soraw!",
      FIELD_IS_REQUIRED: "Májburiy maydan",
      INvALID_EMAIL_ADDRESS: "Nadurıs elektron pochta adresi",
      MINLENGTH_REQUIRED: "Maydan keminde {{requiredLength}} belginen ibarat bolıwı kerek",
      MAXLENGTH_REQUIRED: "Maydan keminde {{requiredLength}} belginen kóp bolıwı kerek",
      MIN_REQUIRED: "Uzınlıq {{min}} den kóp bolıwı kerek",
      MAX_REQUIRED: "Uzınlıq {{max}} den kem bolıwı kerek",
      PATTERN_REQUIRED: "Maydan tómendegi belgiler arasında bolıwı kerek {{requiredPattern}}",
      BETWEEN: "Bahalar {{x}} hám {{y}} arasında bolıwı kerek",
      INvALID_LENGTH: "INN {{minLength}} hám {{maxLength}} arasında bolıwı kerek",
      IP_NOT_vALID: "Nadurıs IP-mánzil kirgizildi",
      CONFIRM_PASSWORD_ERROR: "Paroldı tastıyıqlawda qátelik boldı!",
      NUMERIC: "Bul maydanda tek nomerler jazıladı!",
      LOWERCASE: "Bul maydanda tek kishi háripler jazıladı!",
      UPPERCASE: "Bul maydanda tek úlken háripler jazıladı!",
      MAX_FILE_SIZE: "Fayl kólemi {{size}} den aspawı kerek",
      TWO_Date_Error: "Siz 2 sáne tańlawıńız kerek!",
      DATE_EMPYTE: "Maydan bos bolmawi kerek!",
      ERROR_RESPONSE: "Nadurıs maǵlıwmat qaytarıldı!(422)",
      ERROR_ACCESS:"Login yamasa parol notog'ri",
      NTF_CANNOT_EDIT: "Bul túrdegi bildiriw xatılardı tahrirlab bolmaydı",
      COATO_REQUIRED: 'Wálayat yamasa Rayondı tańlaw májburiy!',
      COATO_REQUIRED_TEXT: 'Ótinish rayon yamasa qala saylań!'
    },

    TOAST: {
      TITLE: {
        SUCCESS: "Tabıslı",
        CANCELLED: "Biykar etilgen",
        WARNING: "Eskertiw",
        ERROR: "Qátelik",
        INFO: "Maǵlıwmat",
        DOWNLOAD: "Júklep alıw",
        EXPORT_EXCEL: "Excel fayl tabıslı júklep alındı!",
        IMPORT_EXCEL: "Excel fayl tabıslı import etildi!",
        RESIDENT_ID: "Bul keste qatarı emmet abonent identifikatori emes",
      },
      TOAST_DELETE: "Saylanǵan keste qatarı tabıslı óshirildi!",
      TOAST_SELECT_ROW: "Ótinish, qatardı saylań!",
      TOAST_SUCCESS: "Maǵlıwmat tabıslı qosıldı!",
      TOAST_UPDATE: "Maǵlıwmat tabıslı tahrirlandi!",
      TOAST_STATUS_UPDATE: "Shaqırıq jaǵdayı tabıslı ózgertirildi",
      TOAST_ADD_COATO: "Shaqırıq qosıw ushın COATO saylań",
      TOAST_MULTIPLE_SELECT_ROW: "Ótinish, qatarlardı saylań!",
      TOAST_WARNING_RESIDENT: "Bul abonent kárxana jumısshısı emes!",
      TOAST_ERROR_NOT_EMPLOYEE: "Bul abonent kárxana jumısshısı emes!",
      TOAST_WARNING_RESIDENT_SEARCH: "Abonent tabilǵan zatdi",
      TOAST_WARNING: "Eger fayl strukturası qanday kóriniste bolıwın bilmeseńiz úlgi ushın fayldı júklep alıwıńız múmkin",
      SUCCES_ACCESS:"Sistemaǵa tabıslı kirildi!",
      SUCCESS_NO_ACCESS: 'Siz sistemadan paydalanıw huqıqına iye emessiz!',
      ThisEmployeeIDDoesNotExist: "Bul xızmetker IDsi joq, Autentifikatsiya jurnalın kóriw ushın xızmetker IDsi kerek!",
      DoubleIncident:"Ekinen artıq belgilep bilmaysiz!",
      MinCalendar:"Jumıs waqtın biriktiriw ushın keminde bir kún saylań ",
      CameraNotAllowed:"Kameraǵa kiriwge ruxsat berilmegen. Kamerańızdı sazlang!"
    },

    MANAGEMENT: {
      TABLE_HEADERS: {
        NUMBERS: "№",
        ID: "ID",
        NAME: "Ati",
        COMPANY: "Kompaniyalar",
        DATE1: "03.2023",
        DATE2: "04.2023",
        DATE3: "05.2023",
        DATE4: "06.2023",
        DATE5: "07.2023",
        DATE6 : "08.2023",
        DATE7: "09.2023",
        ACTION: "Háreket",
        ACTION_LINK: "Ótiw",
      },
      USER_ROLE: {
        TABLE_HEADERS: {
          ID: "ID",
          PREFIX: "Rol prefiksi",
          NAME: "Rol ati",
          LEvEL: "Dárejesi",
          TYPE: "Tipi",
          COMMENT: "Túsindirme",
          COMMENT_UZ: "Túsindirme (UZ)",
          COMMENT_CRL: "Túsindirme (CRYL)",
          COMMENT_RU: "Túsindirme (RU)",
        },
        FILTER: {
          FILTER_BUTTON: "Ámeldegi háreketler",
          TITLE: "Ruxsat etilgen háreketler",
        },
        INSERT_MODAL: "Jańa rol qosıw",
        UPDATE_MODAL: "Rolni redaktorlaw",
        DELETE_MODAL: {
          TITLE: "Rolni óshiriw",
          TEXT: "Saylanǵan rolni sistemadan alıp taslawchimisiz?",
        },
        TOAST_EXISTS_ROLENAME: "Bul \"Rol ati\" sistemada bar. \nÓtinish basqa at kiritiń!",
      },
      USERS: {
        ROLE: "Rol",
        ADD_MODAL_TITLE: "Paydalanıwshı qosıw",
        EDIT_MODAL_TITLE: "Paydalanıwshı maǵlıwmatların redaktorlaw",
        EDIT_USER: "Maǵlıwmatların redaktorlaw",
        UPDATE_STATUS_MODAL_TITLE: "Paydalanıwshı statusi",
        UPDATE_PASS_MODAL_TITLE: "Paydalanıwshı parolın ózgertiw",
        UPDATE_STATUS: "Statusni ózgertiw",
        UPDATE_PASS: "Parolın ózgertiw",
        NEW_PASSWORD: "Jańa parol",
        NEW_PASSWORD2: "Paroldı qayta Kiritiń",
        ACTIvE: "Aktiv",
        IN_ACTIvE: "Aktiv emes",
        TOAST_EXISTS_LOGIN: "Bul \"Login\" atı sistemada bar. \nÓtinish basqa at kiritiń!",
        RESPUBLIC: "Respublika",
      },
      COMPANY_OPERATIONS: {
        TABLE_HEADERS: {
          ID: "ID",
          NAME: "Ati",
          T_PERIOD: "Texnologiyalıq davr",
          ADDRESS: "Mánzil",
          PHONE: "Telefon",
          TASK: "wazıypalar",
          START_DATE: "Baslanıw sánesi",
          END_DATE: "Tawısıw sánesi",
          STATUS: "Jaǵday",
          RESULT: "Nátiyje",
          KOD: "Kod",
          ACTION: "Háreket",
        },
        SELECT: {
          REGION: "wálayattı saylań",
          DISTRICT: "Rayondı saylań",
          YEAR: "Yil",
          MONTH: "Oy",
        },
        MODAL: {
          TITLE: "Ámeldegi oyni qayta esaplaw",
          TEXT: "Siz haqıyqattan da bir waqtıniń ózinde qayta esaplawdı hám keyin bul kompaniyanı jańa dáwir ótkeriwdi qáleysizbe?",
          SAVE: "Awa, orınlań",
        },
      },
    },

    ChatService: {
      Search: "Qıdırıw...",
      Chats: "Chatlar",
      Select_chat: "Chatni saylań",
      About: "Men haqimda",
      Personal_information: "Jeke maǵlıwmatları",
      view_contact: "Kontaktni kóriw",
      Mute_notifications: "Únsizlik qılıw",
      Block_contact: "Kontaktni bloklaw",
      Clear_chat: "Tariyxni tazalaw",
      Write_message: "Xabarıńızdı jazıń",
      Send: "Jiberiw",
    },

    ShortRegions: {
      A03000: "And...",
      A06000: "Bux...",
      A08000: "Jiz...",
      A10000: "Qash...",
      A12000: "Nav...",
      A14000: "Nam...",
      A18000: "Sam...",
      A22000: "Sur...",
      A24000: "Sır...",
      A30000: "Far...",
      A33000: "Xor...",
      A27000: "Tas v...",
      A26000: "Tas sh...",
      A00002: "Xor Urg...",
      A35000: "KKR",
    },

    ShortRegionsCode: {
      A03000: "And...",
      A06000: "Bux...",
      A08000: "Jiz...",
      A10000: "Qash...",
      A12000: "Túr...",
      A14000: "Ízǵar...",
      A18000: "Sam...",
      A22000: "Sur...",
      A24000: "Sır...",
      A30000: "Far...",
      A33000: "Qor...",
      A27000: "Tas v...",
      A26000: "Tas sh...",
      A35000: "Qara Res...",
      A00002: "Xora Urg...",
    },

    FullRegions: {
      Andijan: "Andijan",
      Bukhara: "Buxara",
      Jizzakh: "Jizzaq",
      Kashkadarya: "Qashqadárya",
      Sortoi: "Navaiy",
      Namangan: "Namangan",
      Samarkand: "Samarqand",
      Surkhandarya: "Surxandaryo",
      SyrDarya: "Sirdaryo",
      Ferghana: "Ferǵana",
      Khorezm: "Xorezm",
      TashkentRegion: "Tashkent wálayat",
      TashkentCity: "Tashkent qala",
      KKR: "KKR",
    },

    Profil: {
      Months: {
        January: "Yanvar",
        February: "Fevral",
        March: "Mart",
        April: "Aprel",
        May: "May",
        June: "Iyun",
        July: "Iyul",
        August: "Avgust",
        September: "Sentyabr",
        October: "Oktyabr",
        November: "Noyabr",
        December: "Dekabr",
      },
      Name: "Profil",
      Request: "Sorawlar",
      Complaint: "Shaǵımlar",
      CallTime: "Ulıwma sáwbet waqtı",
      CallName: "Qońırawlar sanı",
      Offer: "Usınıslar",
      Service: "Xizmet kórsetiw",
      Chat_title: "Kúnlik qabıl etilgen shaqırıwlar sanı",
      days30Statistics: "Sońǵı 30 kúnlik qońırawlar statistikası",
      days30StatisticsTime: "30 kúnlik jámi sáwbet waqtı",
      ImgError: "Súwret kólemi 2 mb den kishkene bolıwı kerek",
      EditProfileMessage: "Maǵlıwmatlar ózgertirildi!",
      FirstAndSecondName: "At Famılıya",
      respublika: "Respublika",
      region: "Region",
      tuman: "Tuman",
      kompaniya: "Kompaniya",
      inspektor: "Inspektor",
      operator: "Operator",
      resident: "Resident"
    },

    Selects: {
      Regions: "wálayatlar",
      Region: "wálayat",
      FromRegion: "Wálayattan",
      ToRegion: "Wálayatqa",
      PES: "PES dizimi",
      RES: "RES dizimi",
    },

    NOTIFICATION_TYPE: {
      MAINTENANCE: "Úzilisler haqqında xabar",
      20 : "Basqa túrdegi xabarlar",
    },

    Notification: {
      Notifications: "Bildiriw xatılar",
      NoNotifications: "Oqılmagan bildiriw xatılar joq",
      AllNotifications: "Barlıq bildiriw xatılar",
      AddNotification: "Bildiriw xatı jiberiw",
      New: "Jańa",
      StartDate: "Baslanıw sánesi",
      EndDate: "Tawısıw sánesi",
      StartTime: "Baslanıw waqtı",
      EndTime: "Tawısıw waqtı",
      MessageDescription: "Xabar mazmunı",
      SelectAll: "Hámmesin tańlaw",
      SelectElementName: "ta wálayat tańlandı",
      SelectUserName: "ta paydalanıwshı tańlandı",
      MessageFull: "Tolıq xabar xarakteristikai",
      MessageSend: "Jiberiw",
      SendName: "Bildiriw xatı jiberiw",
      NotFound: "Tabilǵan zatdi",
      MessageType: "Xabar turi",
      SelectSendUser: "Xabar jiberiletuǵın paydalanıwshı",
      MessageDescriptionUZ: "Xabar mazmunı (UZ)",
      MessageDescriptionRU: "Xabar mazmunı (RU)",
      MessageFullUZ: "Tolıq xabar xarakteristikası (UZ)",
      MessageFullRU: "Tolıq xabar xarakteristikası (RU)",
      Consumer: "Qarıydar",
      AccordingtoCOATO: "COATO boyınsha úzilisler xabarı jiberiw",
      SendMsgToCustomer: "Bir Qarıydarǵa xabar jiberiw",
      TYPE_MAINTENANCE: "Úzilisler haqqında xabar",
      TYPE_NOTIFICATION_FROM_CRM: "Basqa túrdegi xabarlar",
      LoadingProgress: "Jiberilip atır...",
      Responsed: "Tómendegishe xabar jiberildi",
      Error: "Qátelik",
      SelectDistrictCOATO: "Rayon COATOni saylań!",
      Sent: "Jiberildi",
      Edited: "Tahrirlandi",
      FileUploadText: "Fayldaǵı maǵlıwmatlar belgilengen struktura boyınsha tekserip shıǵıladı, " +
        "\n nátiyjesi bildiriw xatı retinde qaytarıladı",
    },

    CallCenter: {
      last3day: "Aqırǵı 3 kúnlik",
      last7day: "Aqırǵı 1 háptelik",
      last15day: "Aqırǵı 15 kúnlik",
      last1month: "Aqırǵı 1 aylıq",
      last3months: "Aqırǵı 3 aylıq",

      SelectPeriod: "Dáwirdi saylań",
      TotalNumberOfCalls: "Qońırawlardıń ulıwma sanı",
      AllowedAppeals: "Shaqırıwlar",
      Completed: "Atqarıldi",
      InProgress: "Processda",
      AverageTalkTime: "Ortasha sáwbet waqtı",
      AllTalkTime: "Ulıwma sáwbet waqtı",
      AverageWaitingTime: "Ortasha kútiw waqtı",
      CaseStatusStatistics: "Jumıs jaǵdayı statistikası",
      ListLastCalls: "Aqırǵı qońırawlar dizimi",
      Consumer: "Qarıydar",
      Phone: "Telefon nomeri",
      WaitingTime: "Kútiw waqtı",
      Status: "Jaǵday",
      Time: "waqıt",
      CalculationStatus: "Esap jaǵdayı",
      Accepted: "Qabıl etilgen",
      Skipped: "Ótkerip jiberilgan",
      SelectRegionLabel: "ta wálayat tańlandı",
      DuringConversation: "Sáwbet waqtı",
      ActiveCall:"Aktiv qo'ngiroqlar",
      ActiveOperators:"Aktiv Operatorlar",
      Online:"onlayn",
      Offline:"oflayn",
      QueueCall:"Náwbettegi qońırawlar ",
      CallCount:"Jámi qońırawlar ",
      NotForwardCallCount:"Yo'naltirilmagan qońırawlar ",
      ForwardCallCount:"Baǵdarlanǵan qońırawlar "
    },

    ROLES: {
      RepublicAdmin: "Respublika admin",
      RegionAdmin: "Region admin",
      DistrictAdmin: "Rayon admin",
      CompanyAdmin: "Kompaniya admin",
      Inspector: "Inspektor",
      Operator : "Operator",
      Resident: "Resident",
      NameofROL: "Rol",
      DEPARTMENT_HEAD: "Bólim basshıı",
      ADMIN: "ADMIN",
      OPERATOR: "OPERATOR",
      AGENT: "AGENT",
      PERFORMER: "Orınlawshı"
    },

    LEVELS: {
      Republic: "Respublika",
      Region: "Region",
      District: "Rayon",
      Company: "Kompaniya",
      Inspector: "Inspektor",
      Operator : "Operator",
      Resident: "Resident",
    },

    PageTitle: {
      Home: "Bas bet",
    },

    ChangeMenu: {
      Mega_Menu: "Úlken joqarı menyu",
      Dropdown_Menu: "Kishi joqarı menyu",
    },

    Settings: {
      Name: "Sazlamalar",
    },

    Date: {
      From: "Dan",
      Tap: "Gacha",
      StartWork: "Jumıstıń baslanıwı",
      EndWork: "Jumıstıń aqırı",
      Empty: "Hesh ne tabilǵan zatdi",
      NotTypes: "Shaqırıq túrleri joq",
    },

    Button: {
      Search: "Qıdırıw",
      Clear: "Tazalaw",
      Accept: "Qabıllaw",
      Reject: "Biykarlaw etiw",
      Disable: "Óshiriw",
      Export: 'Eksport',
      ExportExcel: 'Excel ge eksport qılıw',
      RegionOrDistrict: 'Wálayat/Rayon',
    },

    AccountType: {
      ELECTRICITY: "Elektr",
      REACTIVE: "Reaktiv",
      BUST: "Pribor",
      FINE: "Penya",
      COLLECTION: "Kemshilik",
      SERVICES: "Xızmetler",
      ECO_ENERGY: "Ekologiyalıq energiya (-A)",
      TRANSPORTATION: "Xızmet (transport ushın)",
    },

    AvailabilityofReservation: 'Bronning bar ekenligi',
    TotalCost: 'Jámi ǵárejet (sum)',
    AnnualVolumeUnderAgreement: 'Shártnama bo\'yicha jıllıq kólem (mıń kvt/soat)',
    ReportSubmission: 'Esabat tapsırıw kúni',
    PrepaymentPercentage: 'Aldınan to\'lov procenti',
    SDATOLegalLocation: 'SOATO Yuridikalıq adresi',
    ParentOrganization: 'Joqarı shólkem',
    SDATOLocation: 'SOATO Mánzil',
    Fine: 'Penya%',
    PowerandAmount: 'Quwat hám energiya muǵdarı',
    MainTariff: 'Tiykarǵı tarif toparı',
    AdditionalCode1: 'Qosımsha kod 1',
    AdditionalCode2: 'Qosımsha kod 2',
    AdditionalCode3: 'Qosımsha kod 3',
    InstalledPower: 'Ornatılǵan  quwat',
    Substation: 'Substantsiya',
    TransformerName: 'Transformator nomi',
    TP_Feeder: 'TP + Azıqlantiruvchi',
    FullNameForeman: 'F.I.O usta',
    FullNameController: 'F.I.O qadaǵalawshı',
    CodeBook: 'Kodlar kitapi',
    BooksPage: 'Kitap beti',
    ConnectionTap: 'Jalǵanıw ASKUE ga',
    SubtractedFrom: 'Ajıratıldı',
    Consumer: 'Iste\'molchi',
    Subconsumer: 'Subiste\'molchi',
    FromtheTariff: 'Tarifdan',
    FullView: "Tolıq kóriw",
    View: "Kóriw",
    Bind: 'Biriktiriw',
    BindWorkTime: 'Jumıs waqtın biriktiriw',
    BindBreakTime: 'Tánepis waqtın biriktiriw',
    AddBindWorkTime: 'Jumıs waqtın qosıw',
    AddBindBreakTime: 'Tánepis waqtın qosıw ',
    Edit: "Ózgertiw",
    Delete: "Óshiriw",
    Spy: "Esitiw",
    Page: "Bet",
    Login: "Login",
    Password: "Parol",
    Logout: "Shıǵıw",
    LoginSystem: "Sistemaǵa kirisiw",
    LEGAL_ENTITY_DATA: "Yuridikalıq shaxs tuwrısındaǵı maǵlıwmatlar",
    BILLING_MONTH_RECALCULATION_NOT_INCLUDED_DEBITS: "Depetga kiritilmegen esap -kitap ayınıń qayta esap -kitaplari",
    RETURN_PREPAYMENT: "Aldınan tólewdi qaytarıw",
    RECALCULATE_OTHER_REASON: "Basqa sebeplerge kóre qayta esap -kitaplar",
    ABOVE_THE_TARIFF_FOR_EXCEEDING_THE_CONTRACTUAL_VALUE: "Shártnama ma`nisinen asıp ketkenlik ushın tarifdan joqarı",
    FOR_KWTH: "kvt/soat ushın",
    SUM: "Summa",
    INCOMING_BALANCE: "Kiretuǵın balans",
    ACCRUED: "Esaplanǵan",
    ACT_RECALCULATION: "sh.j Qayta esaplaw akti",
    PAYMENT: "Tólew",
    BALANCE_OUT: "Shıǵıwshı qaldıq",
    V_T_CH_FOUND_PAYMENT: "sh.j Tólew tapildi",
    DECOMMISSIONED: "Jazılǵan",
    CLASSIFIED_HOPELESS: "Balans",
    RESTORED_ACCOUNTS_RECEIVABLE: "Keyingi tólew aylar",
    OUTGOING_BALANCE: "Shıǵıwshı balans",
    CURRENT_PAYMENT: "Ámeldegi tólew",
    NEXT_MONTH_PAYMENT: "Keyingi tólew aylar",
    BALANCE: "Balans",
    TOTAL: "Jami",
    V_T_CH_ACT_RECALCULATION: "sh.j Qayta esaplaw akti",
    BALANCE_SALDO: "Balans",
    CONSUMER_CODE:  "Qarıydar kodi",
    GOVERNMENT_ORGANIZATION: "SOOGU",
    CONSUMER_NAME: "Qarıydardıń tolıq atı",
    CONTRACT_VALUE: "Shártnama ma`nisi (kvt/soat)",
    OVERDUE_AMOUNT: "Keshiktirilgan muǵdar",
    AFFILIATION: "Tiyislilik",
    PREPAYMENT_PERCENTAGE: "Aldınan tólew procenti",
    REPORT_DATE: "Esabat sánesi",
    TARIFF_GROUP: "Tariflar toparı",
    CONTRACT_DATE: "Shártnama sánesi",
    INSTALLED_CAPACITY: "Ornatılǵan quwat",
    ADDRESS: "Mánzil",
    PAYMENT_CODE: "Tólew kodi",
    REPORT_NUMBER: "Esabat nomeri",
    RESIDENT: "Rezident",
    ConsumerCard: "Qarıydar kartasi",
    PERSONAL_CONSUMER_CARD: "Лицевая карта потребителя",
    PERSONAL_ACCOUNT: "Jeke esap beti",
    DISTRICT_LOCATION: "Местоположение",
    Neighborhood: "Máhelle",
    NOTES: "Esletpe",
    METER_NUMBER: '№ Esaplaǵısh',
    SHUTDOWN_DATE: 'O\'chirish sánesi',
    INDICATION_INSTALLING_METER: 'Esaplagichni o\'rnatishda ko\'rsatma',
    DATE_INSTALLATION_METER: 'Esaplagichni o\'rnatish sánesi',
    COUNTER_STATUS: 'Esaplaǵısh jaǵdayı',
    METER_TYPE: 'Esaplaǵısh turi',
    CAPACITY: 'Kólem',
    TRANSFORMATION_KOEFF_KT: 'Trans koefficient',
    ASKUE: 'ASKUE',
    SOLAR_PANEL: 'Quyash paneli',
    TARIFF_SUM: 'Tarif (sum)',
    SUMNAME: 'sum',
    LAST_BYPASS: 'Aqırǵı tur',
    LAST_BYPASS_DATE: 'Aqırǵı sapar sánesi',
    SEAL: 'Sertifikat',
    SEAL_NUMBER: 'Sertifikat №',
    DATE: 'Sana',
    DEADLINE_TO: 'Múddet',
    ACT_INVERTORY: 'Qayta esaplaw akti (inventarizatsiya)',
    READING: 'Ko\'rsatma',
    PENALTY_AMOUNT: 'Peniya muǵdarı',
    AMOUNT_PER_EP_E: 'EP/e ushın muǵdar',
    ACT_AMOUNT: 'Aktga muwapıq muǵdar',
    TOTAL_AMOUNT: 'Ulıwma baha',
    RECOIL_INDICATION_MINUS_A: 'Keyin basıp o\'qish (-A)',
    INCOMING_BALANCE_KWTH: 'Kirisiw balansı kvt/soat',
    INCOMING_BALANCE_SUM: 'Balanstı ashıw summasi',
    CALCULATION: 'Esaplaw',
    CALCULATION_TYPE: 'Esaplaw turi',
    CONTRACT_VALUE_KWH: 'Íyt. LED (kvt/soat)',
    AVERAGE_DAILY_CONSUMPTION_KWH: 'O\'rt. táwliklik sarp etiw (kvt/soat)',
    AVERAGE_MONTHLY_CONSUMPTION_KWH: 'O\'rt. aylıq sarp etiw (kvt)',
    ESTIMATED_READING_FOR_LAST_MONTH: 'O\'tgan ay ushın R. h p. k',
    ACCRUED_DAY: 'Esaplanǵan (kún)',
    ACCRUED_SUM: 'Esaplanǵan (so\'m)',
    ACCRUED_KWH: 'Esaplanǵan (kvt/soat)',
    RECALCULATION_DAYS: 'Qayta esaplaw (kún)',
    RECALCULATION_KWH: 'Qayta esaplaw (kvt.s)',
    RECALCULATION_BY_AVERAGE_DAILY: 'O\'rt. kúnlik qayta esaplaw',
    PENALTY_CHARGED_SUM: 'Esaplanǵan járiyma (so\'m)',
    PENALTY_RECALCULATION: 'Járiymalardı qayta esaplaw',
    ESTIMATED_INDICATION_CURRENT_MONTH: 'Ámeldegi aynıń R. h',
    RECALCULATION: 'Qayta esaplaw',
    WRITE_OFF: 'Esaptan o\'chirish',
    PAYMENT_CURRENT: "Ámeldegi tólew",
    PAYMENT_RECALCULATION: "Tólewdi qayta esaplaw",
    ELECTRICITY: 'Elektr',
    ACT_VIOLATED: 'Buzılıw akti',
    UNLEARNED_CONSUMPTION: 'Esapsız iste\'molchi',
    FINE: 'Járiyma',
    POV_CONNECTIONS: 'Pov.jalǵanıwlar',
    SelectRegion: 'Aymaqtı saylań',
    RECALCULATION_DATE: 'Qayta esaplaw sánesi',
    BYPASS_INDICATION_OF_RECALCULATION: 'Qayta esaplaw waqtında aylanıp o\'tish ko\'rsatkichi',
    BYPASS_INDICATION_OF_RECALCULATION_A_MINUS: 'Qayta esaplaw waqtında aylanıp o\'tish ko\'rsatkichi (-A)',
    ALL_AMOUNT: 'Ulıwma esap',
    UNLEARNED_CONSUMPTION_LONG: 'Esapqa alınbaǵan iste\'mol',
    ACT_RECONNECTION: 'Qayta jalǵanıw',
    ResDataInvalid: 'Serverden jiberilgen ma\'lumotlarda qátelik!',
    LAST_PAYMENT_SUM: 'Aqırǵı to\'lov (so\'m)',
    LAST_PAYMENT_DATE: 'Aqırǵı to\'lov sánesi',
    PREVIOUS_BYPASS: 'Aldınǵı ko\'rsatma',
    PREVIOUS_DATE_BYPASS: 'Aldınǵı sapar sánesi',
    LOSS_AMOUNT: 'Yo\'qotish muǵdarı',
    ONES_WITHOUT: 'Ro\'yxatga alınbaǵansız',
    VOSSTA_DEB: 'Áwmetsiz (DEB) paket',
    BUILDING_FLAT_NUMBER: 'Shańaraq/uy',
    METER: 'Esaplagich',
    INSTALLATION: 'O\'rnatish',
    NUMBER_ICON: '№',
    FROM: 'Dan',
    BEFORE: 'Gacha',
    INVENTORY: 'Inventarizatsiya',
    PERIOD: 'Davr',
    PREVIOUS: "Bar edi",
    CURRENT: "Boldı",
    BALANCE_IN: "Kiretuǵın qaldıq",
    START_DATE: "Bası ",
    TERMINATION: "Aqırı ",
    CALCULATION_CATEGORY: "Esaplaw túri",
    COUNT_DAY: "Kún sanı ",
    KVT_A_PLUS: "kVtS(A+)",
    KVT_A_MINUS: "kVtS(А-)",
    DIFFERENCE: "Parq",
    READING_A_PLUS: "Kórsetpe А+",
    READING_A_MINUS: "Kórsetpe А-",
    CURRENT_PERIOD_DATE: "Esap-kitap dáwiri",
    AVERAGE_DAY_EXP: "Ortasha kúnlik tutınıw ",
    AVERAGE_DAY: "Ort.kún",
    SETTLEMENT_DATE: 'Esaplaw sánesi',
    READING_CALCULATION: 'Esap ko\'rsatgichi',
    KWT: 'kvt',
    LOSS: 'Yo\'qotish',
    EDUCATION_PRICE: 'Bahalash',
    VIOLATION: 'Buzılıw',
    OTHER_CALCULATION: 'Basqa esap -kitaplar',
    BYPASS_HISTORY: 'O\'tish tariyxı',
    PAYMENT_HISTORY: 'To\'lov tariyxı',
    BANK_DOCUMENT_TYPE: 'Bank hújjeti turi',
    DOCUMENT_NUMBER: 'Hújjet nomeri',
    ENTER_DATE: 'Kiritilgen sana',
    DOCUMENT_DATE: 'Hújjet sánesi',
    PAYMENT_AMOUNT: 'To\'lov muǵdarı',
    PAYMENT_PURPOSES: 'To\'lovnoma belgilew',
    CREATED_BY: 'Tárepinen jaratılǵan',
    UPDATED_BY: 'Tárepinen o\'zgartirilgan',
    PREVIOUS_BYPASS_READING_AP: 'Aldınǵı chetlab o\'tish (A+)',
    PREVIOUS_BYPASS_READING_AM: 'Aldınǵı chetlab o\'tish (A-)',
    SN_METER: 'SN esaplagich',
    CONSUMPTION_PLUS: 'Iste\'mol (+A)',
    RECOIL_MINUS: 'Keyin basıp qaytıw (-A)',
    BYPASS_DATE: 'O\'tish sánesi',
    SOURCE: 'Derek',
    AGENT: 'Agent',
    UPDATED_AT: 'O\'zgartirish sánesi',
    LOGIN: 'Kirisiw',
    STATUS: 'Jaǵday',
    PAYMENT_DATE: 'To\'lov sánesi',
    TARIFF: 'Tarif',
    PAYMENT_TYPE: 'To\'lov turi',
    CALCULATION_PERIOD: 'Esaplaw dáwiri',
    SENDER: 'Jiberuvchi',
    EXTRA_OPTIONS: 'Qo\'sh.parametrlari',
    SUMM: 'Summa',
    TOTALL: 'Jami',
    NEGOTIABLE_CARD: "Teris karta",
    SHOWCASE: "vitrina",
    DEBT_PAYMENT_BEFOREHAND_SHORT: 'Artıqsha jumıs (-aldınǵı)',
    IncomingCallRestriction: "Kiriwshi qoŋırawlarǵa ruqsat beriw",
    OutgoingCallRestriction: "Shıǵıwshı qońırawlarǵa ruhsat beriw",
    OutgoingCall: "Chiquvchi qoʻngʻiroq",
    PleaseWait: "Kuting",
    FilesUploadedToGeoMap: "Geo kartaǵa júklengen fayllar",
    DownloadCurrentStatus: "Ámeldegi jaǵdaydı júklep alıw",
    UploadNewFile: "Jańa fayl júklew",
    UploadedByWho: "Kim tárepinen júklengen",
    FileName: "Fayl nomi",
    RejectedFile: "Biykarlaw etilgen",
    UploadTime: "Júklengen waqtı",
    Explanation: "Túsindirme",
    Downloaded: "Júklendi",
    FileApproved: "Fayl tastıyıqlandi",
    FileDisapproved: "Fayl tastıyıqlanmadi",
    Upload: "Júklew",
    Uploading: "Júklenmoqda",
    SelectCSVFile: "CSv fayldı saylań",
    CSVFileUploadNote: "Tek CSV formatdaǵı fayl júklew múmkin. Fayl ólshemi 1 MB den aspawı kerek.",
    CommentOptional: "Túsindirme (Qálegen)",
    ErrorCSVSelect: "Qáte: CSv fayldı saylań!",
    ErrorSizeLimit: "Qáte: fayl kólemi 1 MB shegaradan asıp ketti!",
    CallsForwardCount: "Basqa nomerge baǵdarlanǵan qońırawlar sanı",
    IvRHistoryCount: "IVRga kirip maǵlıwmat alǵanlar sanı",
    MissedCallsCount: "Ótkerip jiberilgen qońırawlar sanı",
    AuthenticationLog: "Autentifikatsiya jurnali",
    Browser: 'Brauzer',
    IP_adress: "IP-mánzil",
    Location: "Jaylasıw (Location)",
    LoginAttemptTime: "Kiriwge urınıw waqtı",
    RolePermissions: "Rol ruhsatlari",
    ThisRolIdNotDef: " Bul rol IDsi tabilǵan zatdı!",
    Permissions: "Ruhsatlar",
    PerToRole: "roliga tiyisli ruhsatlar",
    Module: "Modul",
    UpdateAt: " Jańalanǵan waqtı",
    Confirm: " Tastıyıqlaw",
    ToBack: " Keyin basıp",
    confirmWarning: "Ózgerislerdi tastıyıqlaysizmi?",
    OperatorOnline: "Operator aktiv",
    OperatorOffline: "Operator aktiv emes",
    NotFoundCOATO: "COATO tabilǵan zatdı!",
    NotBeenDefinedCOATO: "Paydalanıwshı kiriw ruxsatına iye COATOlar dizimi tabilǵan zatdi",
    CommunicationHasDeteriorated: "Baylanıs sapası jamanlasdı!",
    NoSound: "Dawıssız",
    ActivityStatus: "Aktivlik jaǵdayı (sistemaǵa kirisiw)",
    ConnectionStatus: "Operator hālati (Onlayn yāki Oflayn)",
    Online: "Onlayn",
    Offline: "Oflayn",
    OffNotification: "Bildiriw xatın óshiriw",
    OnNotification: "Bildiriw xatın qosıw",
    NameOrEmailSearch: "At yamasa E-mail arqalı qıdırıw ",
    NameOrCoatoSearch: "CAOTO yamasa At arqalı qıdırıw ",
    OnSound: "Dawıstı qosıw",
    CallForwarding: "Qońırawdı báǵdarlaw",
    WaitingClientsWindow: "Kutayotgan klientler aynası",
    CloseWindow: "Aynanı jabıw",
    CustomerChat: "Klient sáwbeti",
    EndCall: "Qońırawdı tamamlaw",
    OpenWindow: "Aynanı ashıw",
    SureEndCall: "Rostan da bul qońırawdı tamamlawdı qáleysizbe?",
    Termination: "Tamamlaw",
    CallEnd: "Qońıraw tamamlanıldı",
    ContactOperator: "Operator menen onlayn sáwbet",
    REN: "“HUDUDIY ELEKTR TARMOQLARI” AJ",
    KVT_A: "kVt/soat",
    OldSumm: "Aldınǵı boyınsha jámi",
    NewSumm: "Jańası boyınsha jámi",
    PaymentDate: "Tólew kúni",
    DateFrom: "Den",
    DateTo: "G'acha",
    Days: "Kunlar",
    RECALCULATION_NOT_FOUND: "Bul ishpaz iste'molşı üçün ştärimäni qayta hesaplaw tabılmadı.",
    OPERATOR_STATISTICS: "Operatorlar statistikasinı körıw",
    AddPermitCoato: "Qosımsha biriktirilgen aymaqlar",
    CoatoNotFoundToastTitle: "Tabilǵan zatdı yamasa kiriw ruxsatıńız bolmaǵan COATOni kirgizdińiz",
    UuidOrStartNotFound: "UUID ya'ki Start parametri joq",
    RecordSuccessfullyDeleted: "Jazuv saǵlıqlı óshirildi",
    FailedToDeleteRecord: "Jazuvdi óshiruǵa bolmadi",
    DurationTime: "Dáwamlılıǵı",
    ViewLink: "Kóruw",
    SentTime: "Jiberilgen waqtı",
    NotificationMonitoring: "Bildiriw xatılar monıtoringi",
    NTFMonitoringTitle: "Real waqıttaǵı xabar statusini anıqlaw ushın betti jańalang",
    ReadingTime: "Oqılgan waqtı",
    SendEmployees: "Ámeldegi xabar jiberilgen xızmetkerler",
    Read: "Oqılgan",
    Unread: "Oqılmagan",
    FromNewMessage: "dan sizge jańa xabar keldi",
    RestartAccountSipNumber: "Tarmaqta úzilis júz berdi. Akkaunt hám SIP nomerdi qayta jumısqa tusiriń."
  },
};
