<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <ul class="nav navbar-nav d-xl-none align-items-center mr-1">
      <li class="nav-item">
        <a (click)="toggleSidebar('menu')" class="nav-link menu-toggle">
          <keen-icon
            class="font-large-1"
            color="primary"
            name="element-11"
            type="duotone"
          ></keen-icon>
        </a>
      </li>
    </ul>
    <div
      class="dropdown-content navbar-container p-0 d-flex justify-content-between align-items-center main-menu-content"
      style="gap: 2rem"
    >
      <div class="dropdown">
        <keen-icon
          (click)="toggleDropdown()"
          class="font-large-1 cursor-pointer"
          color="primary"
          name="abstract-29"
          type="duotone"
        ></keen-icon>
        <div [ngClass]="{ show: isDropdownOpen }" class="dropdown-menu">
          @for (i of menu; track i.id) {
            <a
              class="dropdown-item d-flex align-items-center px-1"
              (click)="menuChanged(i.id)"
              [ngClass]="{ active: router.url.startsWith(i.url) }"
              routerLink="{{ i.url }}"
              style="gap: 0.25rem"
            >
              <keen-icon name="{{ i.icon }}" color="primary" type="duotone"/>
              {{ i.translate | translate }}
            </a>
          }
        </div>
      </div>

      <ul class="nav navbar-nav">
        <li class="nav-item">
          <a [routerLink]="navigateFunction()" class="d-flex">
            <span
              class="brand-logo d-flex align-items-center"
              style="gap: 0.6rem"
            >
              <img
                [src]="defaultCoreConfig.app.appLogoImage"
                alt="brand-logo"
                height="36"
                width="40"
              />
              <h2
                class="brand-text brand-text-content mb-0 text-bold font-medium-4"
              >
                {{ coreConfig.app.appTitle }}
              </h2>
            </span>
          </a>
        </li>
      </ul>

      <ul
        class="nav navbar-nav d-lg-flex d-none-content"
        core-menu
        id="main-menu-navigation"
        layout="horizontal"
        style="gap: 0.5rem"
      ></ul>
    </div>
    <!--/ Toggle skin -->
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <li
      class="nav-item mr-2 mr-lg-0 darkMode"
      style="margin-top: 5px"
    >
      <div
        (click)="navigateToChat()"
        class="nav-link nav-link-style btn cursor-pointer"
      >
        <keen-icon class="font-large-1" name="messages" type="duotone"/>
      </div>
    </li>
    <!-- <li
      class="nav-item mr-1 mr-lg-0 darkMode"
      style="margin-top: 3px !important"
    >
      <div
        (click)="toggleDarkSkin()"
        class="nav-link nav-link-style btn cursor-pointer"
      >
        @if (currentSkin == 'dark') {
          <keen-icon name="sun" type="duotone" class="font-large-1"/>
        } @else {
          <keen-icon name="moon" type="duotone" class="font-large-1"/>
        }
      </div>
    </li> -->
    <li
      class="nav-item dropdown menu-content mr-lg-0 mr-50 d-1xl-none-content"
      ngbDropdown
      style="margin-top: 7px"
    >
      <div class="nav-link dropdown-toggle cursor-pointer" ngbDropdownToggle>
        <keen-icon
          color="primary"
          name="row-horizontal"
          size="medium-5"
          type="duotone"
        />
      </div>
      <div ngbDropdownMenu style="left: -20px !important">
        @for (layout of layoutOptions; track layout) {
          @if (localStorageValue == layout.key) {
            <div
              (click)="setLayout(layout.key)"
              class="cursor-pointer d-flex align-items-center"
              style="background-color: rgba(77, 74, 232, 0.12)"
              ngbDropdownItem
            >
              <keen-icon name="{{ layout?.icon }}" color="primary" type="duotone"/>
              <span style="margin-left: 5px">{{ layout?.title | translate }}</span>
            </div>
          } @else {
            <div
              (click)="setLayout(layout.key)"
              class="cursor-pointer d-flex align-items-center"
              ngbDropdownItem
            >
              <keen-icon name="{{ layout?.icon }}" color="primary" type="duotone"/>
              <span style="margin-left: 5px">{{ layout?.title | translate }}</span>
            </div>
          }
        }
      </div>
    </li>

    <!--    i18n-->
    <li class="nav-item dropdown dropdown-language mr-lg-0 mr-50" ngbDropdown>
      <div
        class="nav-link dropdown-toggle cursor-pointer"
        id="dropdown-flag"
        ngbDropdownToggle
      >

        <img *ngIf="languageOptions[_translateService.currentLang]"
             [src]="languageOptions[_translateService.currentLang]?.src" height="21px" style="margin-top: 5px;"
             width="21px" alt="img"/>
      </div>
      <div ngbDropdownMenu>
        <div
          (click)="setLanguage(lang)"
          *ngFor="let lang of coreConfig.app.appLanguages"
          class="cursor-pointer flex gap-2 "
          ngbDropdownItem
        >
          <img [src]="languageOptions[lang]?.src " height="14px" width="18px"/>
          {{ languageOptions[lang]?.title }}
        </div>
      </div>
    </li>

    <!--    @defer (when isAuthenticated) {-->
    <!--      @if (isAuthenticated) {-->
    <!--        <app-navbar-notification class="notification"></app-navbar-notification>-->
    <!--      }-->
    <!--    }-->
    <!-- User Dropdown -->

    <app-navbar-notification class="notification"></app-navbar-notification>

    <li *ngIf="isAuthenticated" class="nav-item dropdown-user" ngbDropdown>
      <a
        aria-expanded="false"
        aria-haspopup="true"
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
      >
        <!--        <div class="d-flex user-nav d-sm-flex d-none">-->
        <!--          <span class="user-name font-weight-bolder">{{-->
        <!--            currentUser.name || "admin"-->
        <!--          }}</span-->
        <!--          ><span class="user-status"></span>-->
        <!--        </div>-->

        <span class="avatar">
          <img
            alt="avatar"
            class="round"
            height="40"
            [src]="avatarValid ? avatar : 'assets/images/avatars/user.png'"
            width="40"
          />
          <span class="avatar-status-online"></span>
        </span>
      </a>
      <div
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
        ngbDropdownMenu
      >
        <a [routerLink]="['/profile']" class="d-flex" ngbDropdownItem>
          <span [class]="'mr-50'" [data-feather]="'user'"></span>
          {{ "Profil.Name" | translate }}
        </a>
        <!-- <a [routerLink]="['/settings']" class="d-flex" ngbDropdownItem>
          <span [class]="'mr-50'" [data-feather]="'settings'"></span>
          {{ "Settings.Name" | translate }}
        </a> -->
        <div class="dropdown-divider"></div>
        <a (click)="logout()" class="d-flex" ngbDropdownItem
        ><span [class]="'mr-50'" [data-feather]="'power'"></span>
          {{ "Logout" | translate }}</a
        >
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
