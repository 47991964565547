export const locale = {
  lang: "ru",
  data: {
    Menu: {
      Spravochnik: {
        Title: "Справочники",
        Children: {
          Departments: "Филиалы",
          Branches: "COATO филиалы",
          TicketTypes: "Типы обращений",
          TicketStatuses: "Типы статусов",
          TicketPriorities: "Типы приоритетов",
          Activities: "Виды деятельности",
          Boards: "Обращения",
          Projects: "Проекты",
        },
      },
      Home: "Главная",
      CRM: "Панель управления",
      HelpDesk: "Окно справки",
      GeoMap: "Геокарта",
      GeoMapSetting: "Настройки геокарты",
      CallCenter: "Call центр",
      ChatService: "Чат",
      Statistics: "Статистика",
      Consumers: "Потребители",
      All: "Общие",
      LegalConsumers: "Юридические потребители",
      LegalConsumer: "Юридический потребитель",
      PhysicalConsumers: "Бытовые потребители",
      PhysicalConsumer: "Бытовой потребитель",
      VIPCustomers: "VIP клиенты",
      BlockContacts: "Черный список",
      VIPCustomer: "VIP клиент",
      Users: "Сотрудники",
      Operators:"Операторы",
      Management: "Управление",
      Appeals: "Обращения",
      Marketing: "Маркетинг",
      Roles: "Роли",
      UserMonitoring: "Отслеживание сотрудников",
      Services: "Услуги",
      Calls: "Звонки",
      CallsHistory: "История",
      CallForward: "Переадресованные",
      LiveCalls: "На линии",
      QueueCalls: "Следующие",
      Evaluation: "Оценка",
      SendNotificatin: "Отправить уведомление",
      SendNotificationToClients: "Отправить клиентам",
      SendNotificationToOperators: "Отправить операторам",
      AssignRoles: "Прикрепление роли",
      RoleNotMessage: 'Роль недоступна!',
      CallRecording: "Записанные",
      PersonnelManagement: "Кадровый менеджмент",
      Tickets: "Обращения",
      Contracts: "Контракты",
      Reports: "Отчеты",
      Report: "Отчёт",
      RequestsReports: "Области",
      StatisticalReports: "Статистические отчеты",
      TimeReports: "По периоду времени",
      OperatorReports: "По операторам",
      Main: "Основной",
      ViewContract: "Просмотр контракта",
      UserCode: "Код потребителя",
      ConsumerCode: "Потребительский код",
      ContractNumber: "Номер контракта",
      FullNameofConsumer: "Полное имя потребителя",
      ShortNameofConsumer: "Короткое имя потребителя",
      INN: "ИНН",
      Account: "Учетная запись",
      ContractDate: "Дата контракта",
      StatisticalData: "Статистические данные",
      AddressAndBankDetails: "Адрес и банковские реквизиты",
      ConditionsofEnergy: "Условия энергоснабжения",
      PowerandAmount: "Мощность и количество энергии",
      OtherInfo: "Другая информация",
      SubtractedFrom: "Вычитается из",
      ListMeter: "Список счетчиков",
      ControlCode: "Код управления",
      OKPO: "ОКПО",
      Subgroup: "Подгруппа СООГУ",
      Codeby: "Код от ОКОНХ",
      FormCodeby: "Код формы от FSC",
      FormCodeby1: "Код формы по COPF",
      PlaceofRegistration: "Место регистрации",
      Prefiks:"Префиксы",
      Incidents: "Инциденты",
      BotReport:"Отчет о боте",
      Spy:"Шпионаж",
      SipNumbers:"SIP-номера",
      BreakTime: 'Время перерывов',
      WorkTime: 'Рабочее время',
      CallTime:"Время между звонками",
      NotAnsweredCall:"Пропущенные звонки",
      List: "Список",
      BreakTimes: 'Время перерывов',
      WorkTimes: "Рабочее время",
      ReleAudit:"Реле Аудит",
      LiveStats:"График",
      OperatorReport:"Отчет операторов",
      CoatoSettings: "Разрешения COATO",
      CoatoSettingsTitle: "Права доступа операторов в отношении COATO",
      CommonReport:"Общий отчет",
      PhoneChangeAudit:"Отчет по номеру телефона",
      DeleteChannels:"Отключить каналы звонков",
      VideoStreamMonitoring:"Видеонаблюдение за сотрудниками"
    },

    Block: {
      Active: "Активный",
      InActive: "Неактивный",
    },
    Time:{
      Hour:"Час",
      Minute:"Минута",
      Second:"Соня"
    },

    Actions: {
      Title: "Действия",
      Add: "Добавить",
      AddTicket: "Добавить обращение",
      Cleaning: "Очистить",
      SendNotification: "Отправить уведомление",
      Update: "Изменить",
      UpdateCallData: "Обновить данные вызова",
      Delete: "Удалить",
      DeleteImg: "Вы действительно хотите удалить это изображение?",
      DeleteImgMessage: "Изображение удалено!",
      DeleteIncidentsWarning: "Вы уверены, что хотите удалить этот инцидент?",
      DeleteIncidentsMessage: "Данные об инциденте удалены!",
      UpdateIncidentsTitle: "Подтвердить редактирование",
      UpdateIncidentsWarning: "Вы уверены, что хотите отредактировать этот инцидент?",
      UpdateIncidentsMessage: "Информация об инциденте была отредактирована!",
      ForwardCall: "Переадресация вызова",
      Buttons: {
        Submit: "Сохранить",
        Cancel: "Отменить",
        Open: "Открыть",
        LoadMore:"Загрузи больше",
        Forward: "Переадресация",
        WorkBind:"Приложение рабочего времени"
      },
      Toast: {
        Massage: "Подтвердите удаление",
        ToastDelete: "Выбранная строка таблицы удалена успешно!",
        ConfirmMassage: "Внимание подтвердите удаление!",
        ConfirmButton: "Удалить",
        CancelButton: "Отмена",
        Yes: "Да",
        No: "Нет",
      },
    },

    Dashboard: {
      Statistics: "Статистика",
      AppealOfNumbers: "Количество обращений в разрезе регионов",
      CallOfNumbers: "Количество звонков по регионам",
      AppealOfStatus: "Статистика статуса обращений",
      AppealOfModuleType: "Статистика откуда генерируются обращения",
      AppealOfTypeByRegions: "Статистика по типам обращений в разрезе регионов",
      Done: "Выполнено",
      InProgress: "В процессе",
      Checked: "Проверено",
      Backlog: "В обработке",
      Archived: "Архивировано",
      AppealsOfMonths: "Количество обращений в разрезе месяцев",
      Appeals: "Обращения",
      GradeByUser: "Оценки, выставленные пользователями",
      Users: "Пользователи",
      Excellent: "Отличный",
      Good: "Хороший",
      Satisfactory: "Удовлетворительно",
      NotSatisfactory: "Не удовлетворительно",
      Bad: "Плохо",
      AppealOfType: "Статистика по типам обращений",
      Complaints: "Жалобы",
      Suggestions: "Предложения",
      MustBeDone: "Должен быть выполнен",
      Completed: "Завершено",
      DDone: "Сделано",
    },

    Reports: {
      Requests: "Обращения",
      Statistical: "Статистика",
      Users: "Пользователи",
      Tickets: "Обращения",
      Time: "Длительность звонка",
      Region: "Pегионы",
      Operator: "Операторы",
      SubName: {
        Time: "В зависимости от продолжительности разговора",
        Region: "По регионам",
        Operator: "По операторам",
      },
    },

    Table: {
      Pagination: "Посмотреть с {{starterItemNumber}} до {{endItemNumber}} из {{totalPages}} записей",
      HeaderNumbers: "№",
      PaginationShow: "Показать",
      NoData: "Данные не найдены!",
    },

    Components: {
      Users: {
        Title: "Пользователи",
        TableHeader: {
          Name: "Имя",
          Email: "Электронная почта",
          EmailVerifiedAt: "Время подтверждения электронной почты",
          CreatedAt: "Время создания",
          UpdatedAt: "Время обновления",
          CoatoBranch: "COATO филиал",
          CoatoDepartment: "COATO отдел",
          SipNumber: "SIP номер",
          Phone: "Телефон",
          Address: "Адрес",
          Status: "Состояние",
          ACTIVE: "Активный",
          IN_ACTIVE: "Не активно",
          PERMISSION: "Разрешено",
          FORBIDDIN: "Запрещено",
          LocalPassword: "Локальный пароль",
          LocalPassword2: "Подтвердите локальный пароль",
          ConfirmLocalPassword: "Введите локальный пароль, чтобы открыть приложение.",
          Staff:"Сотрудник"
        },
      },
      Consumers: {
        LegalConsumers: {
          Title: "Юридические потребители",
          TableHeader: {
            ConsumerPersonalAccount: "Код потребителя",
            ConsumerBusinessName: "Имя потребителя",
            AddressNote: "Адрес",
            BookCode: "Книга",
            AccountingStatus: "Состояние расчета",
            CalculationCategory: "Тип расчета",
            ReportStatus: "Состояние отчета",
            ConsumerStatus: "Состояние потребителя",
            MetersCount: "Количество счетчиков",
            ActionColumn: "Движения",
          },
          DateConclusionOfAgreement: "Дата заключения договора",
          ContractNumber: "Номер договора",
          ContracterFullName: "Полное имя потребителя",
        },
        LegalConsumersById: {
          ConsumerPersonalAccount: "Код потребителя",
          SummaryDate: "Дата заключения",
          ContractNumber: "Номер договора",
          ContractRenewalDate: "Дата продления договора",
          CustomerFullName: "Полное имя потребителя",
          CustomerShortName: "Краткое имя потребителя",
          FaceType: "Тип лица",
          INN: "ИНН",
          NDS_RegistrationCode: "Регистрационный код НДС",
          Score: "Счет",
          PromNepromPred: "Пром/Непром/Пред",
          Resident: "Резидент",
          ResidentYes: "Да",
          ResidentNo: "Нет",
          CadastreAddress: "Адрес по кадастру",
          CadastreCode: "Кадастровый номер",
          Sector: "Сектор",
          PINFL: "ПИНФЛ",
          StatisticalInformation: {
            Title: "Статистические даннные",
            PaymentCode: "Код оплаты",
            OKPO: "ОКПО",
            SOOGU: "СООГУ",
            SOOGU_Subgroup: "Подгруппа СООГУ",
            OKED_Code: "Код по ОКЭД",
            FormCode_KFS: "Код формы по КФС",
            FormCode_COPF: "Код формы по КОПФ",
            PlaceOfRegistration: "Место регистрации",
            ConsumerGroup: "Группа потребления",
          },
          Address: {
            Title: "Адрес",
            SOATO_Address: "СОАТО местонахождения",
            Neighborhood: "Махалля",
            Street: "Улица",
            Home: "Дом",
            Home2: "Дом",
            FlatName: "Квартира",
            CorpusName: "Корпус",
            LegalAddress_SOATO: "СОАТО юрид. адрес",
            HigherOrganization: "Вышая организация",
            ContactPhoneNumber: "Контактный номер",
            MobilePhone: "Мобильный телефон",
            EmailAddess: "Почтовый адрес Email",
          },
          BankDetails: {
            Title: "Банковские реквизиты",
            CalculationType: "Tип счёта",
            MFO: "МФО",
            Score: "Счет",
            BranchCode: "Код филиала",
            BranchRecruitment: "Наименование филиала",
            Supervisor: "Руководитель",
            Accountant: "Бухгалтер",
            FIO: "ФИО",
            Position: "Должность",
          },
        },
        PhysicalConsumers: {
          Title: "Физические потребители",
          TableHeader: {
            PersonalAccount: "Лицевой счет",
            Name: "ФИО потребителя",
            Passport: "Паспорт",
            Address: "Адрес",
            Agent: "Aгент",
            TP: "ТП",
            Transformer: "Фидер",
            CalculationType: "Тип расчета",
            CalculationStatus: "Состояние",
            LastReading: "Последний обход",
            LastReadingDate: "Дата последнего обхода",
          },
        },
        PhysicalConsumersById: {
          FaceCard: {
            Title: "Лицевая карточка",
            PersonalAccount: "Лицевой счет",
            MobilePhone: "Мобильный телефон",
            Address: "Адрес",
            Neighborhood: "Махалля",
            StreetAddress: "Адрес улицы",
            Surname: "Фамилия",
            FirstName: "Имя",
            FatherName: "Отчество",
            Reminder: "Примечание",
          },
          Document: {
            Title: "Документ",
            DocumentType: "Типы документов",
            IdCard: "ID карточка",
            Passport: "Паспорт",
            DocumentSeries: "Документ серия и №",
            GivenWhom: "Выдан",
            DateIssue: "Дата выдачи",
            DateBirth: "Дата рождения",
            INN: "ИНН",
            CadastralCode: "Код по кадастру",
            PINFL: "ПИНФЛ",
            Language: "Язык",
          },
          GeneralInformation: {
            Title: "Общие данные",
            Telephone: "Телефон",
            Agent: "Агент",
            Book: "Книга",
            Brigade: "Бригада",
            Status: "Состояние",
            StatusAlive: "Текущий",
            StatusDead: "Мертвый",
            Consent: "Согласие",
            ConsentYes: "Да",
            ConsentNo: "Нет",
          },
          Counter: {
            Title: "Счетчик",
            CounterType: "Тип счетчика",
            SerialMeterNumber: "Серийный номер счетчика",
            SerialNumber: "Серийный номер",
            NoStandardSeals: "№ Пломбы Узстандарт",
            DateUzstandartSeal: "Дата пломбы Узстандарт",
            EnsbytSeals: "№ Пломбы Энсбыта",
            DateEnsbytSeals: "Дата пломбы энсбыта",
            BPI_No: "№ пломбы БПИ",
            BPI_Date: "Дата пломбы БПИ",
            MeterInstallationLocation: "Место установки счетчика",
            ASKUE_Type: "Тип АСКУЭ",
            AgreementDate: "Дата согласия",
            ASKUE_Connect: "Подключение к АСКУЭ",
            ASKUE_ConnectYes: "Да",
            ASKUE_ConnectNo: "Нет",
            CounterStatus: "Состояние счётчика",
            BitPower: "Разрядностъ",
            TransformerCoefficient: "Коэффициент трансформации",
            SolarPanel: "Солнечная панелъ",
            TariffPrice: "Сумма тарифа",
            installationReadingPlus: "Показания на момент установки (+A)",
            installationReadingMinus: "Показания на момент установки (-A)",
            PreviousIndications: "Пред.показания |А|",
            InstallationDate: "Дата установки",
          },
          Certificate: {
            Title: "Сертификат",
            No: "Сертификат №",
            Check: "Проверить",
            ExpirationDate: "Срок годности",
          },
          ConstructionInformation: {
            Title: "Информация о сооружении",
            Substation: "Подстанция",
            Nourishing: "Фидер",
            Transformer: "Трансформатор",
          },
          Agreement: {
            Title: "Договор",
            TariffSum: "Тариф (сум)",
            TariffCode: "Код тарифа",
            TariffName: "Наименование тарифа",
            ContractNumber: "Номер договора",
            DateApplication: "Дата заявления",
            TariffChangeDate: "Дата изменения тарифа",
            DateConclusionContract: "Дата заключения договора",
            ContractRenewalDate: "Дата продления договора",
            TariffImplementationDate: "Дата ввода тарифа",
            SignUp_No_REP: "Регистрационный № и РЭП",
          },
          AdditionalInformation: {
            Title: "Дополнителъные сведения ( по типовому договору раздел VII )",
            LEP: "ЛЭП",
            One_TP: "1 Фидер + ТП",
            Two_TP: "2 Фидер + ТП",
            TypeAccommodation: "Тип жилья",
            ClassResidence: "Категория жилья",
            ApartmentGassed: "Газифицирована ли квартира",
            ApartmentGassedYes: "Да",
            ApartmentGassedNo: "Нет",
            ConnectedCentralSystem: "Подключено к центральному отоплению",
            ConnectedCentralSystemYes: "Да",
            ConnectedCentralSystemNo: "Нет",
          },
          ConsumerAccess_LEP: {
            Title: "Имеет ли потребитель 2х или 3х сторонний ввод ЛЭП-0.4 кв",
            NumberLightingDevices: "Количество бытовой техники (шт)",
            PowerLightingDevices: "Мощностъ осветителъных приборов (кВт)",
            InstalledPower: "Подключенная мощностъ (кВт)",
            NumberSockets: "Количество розеток (кВт)",
            TotalPowerConsumption: "Общая потребляемая мощностъ (кВт)",
            TheNumberPersons: "Количество проживаемых лиц (чел)",
          },
          ContractValue: {
            Title: "Договорная величина (кВтч)",
            January: "Январъ",
            February: "Февралъ",
            March: "Март",
            One_KV: "1 кв",
            AnnualContract: "По договору на год (кВтч)",
            April: "Апрелъ",
            May: "Май",
            June: "Июнъ",
            Two_KV: "2 кв",
            AnnualAmount: "Сумма на год (сум)",
            July: "Июлъ",
            August: "Август",
            September: "Сентябръ",
            Three_KV: "3 кв",
            October: "Октябръ",
            November: "Ноябръ",
            December: "Декабръ",
            Four_KV: "4 кв",
          },
        },
        VIPCustomers: {
          Search: "Номер телефона",
          AllCustomers: "Все клиенты",
        },
        Consumers: {
          Title: "Потребители",
          TableHeader: {
            FirstName: "Имя",
            LastName: "Фамилия",
            MiddleName: "Отчество",
            Phone: "Номер телефона",
            CreatedAt: "Время создания",
          },
        },
        Children: {
          LegalConsumers: "Юридические потребители",
          PhysicalConsumers: "Физические потребители",
        },
        ConsumerModalTitle: "Лицевая карта пользователя",
        Title: "Потребители",
      },
      Spravochniks: {
        SpravochniksBranches: {
          Title: "Филиалы",
          TableHeader: {
            Code: "Код",
            Name: "Название",
            CreatedAt: "Время создания",
            UpdatedAt: "Время обновления",
            Status: "Статус",
            Email: "Email",
            CoatoBranch: "COATO филиал",
            CoatoDepartment: "COATO отдел",
            Time: "Время",
          },
        },
        SpravochniksDepartments: {
          Title: "Отделы",
          TableHeader: {
            Code: "Код",
            CoatoCode: "COATO код",
            Name: "Название",
            CreatedAt: "Время создания",
            UpdatedAt: "Время обновления",
            Status: "Статус",
            Email: "Email",
            CoatoBranch: "COATO филиал",
            CoatoDepartment: "COATO отдел",
            CoatoBranchId: "COATO филиал ID",
            CoatoBranchName: "COATO филиал",
          },
        },
        Projects: {
          Title: "Проекты",
          TableHeader: {
            Name: "Имя",
            ShortDesk: "Краткое название",
            CreatedAt: "Время создания",
            UpdatedAt: "Время обновления",
            Description: "Описание",
          },
        },
      },
      Dashboard: {
        SelectPeriod: "Выберите период",
        RequestType: "Тип запроса",
        SelectRegion: "Выберите область",
        RequestStatus: "Статистика статуса обращений",
        AppealType: "Статистика по видам заявок",
      },
      Tickets: {
        CoatoCode: "COATO код",
        TableTitle: "Список Обращений",
        Type: "Тип",
        Importance: "Важность",
        Status: "Статус",
        Title: "Заголовок",
        Degree: "Уровень",
        Performer: "Исполнитель",
        Priority: "Приоритет",
        DateSelect: "Временной интервал",
        Deadline: "Время окончания",
        Archive: "Архив",
        Archived: "Архивированный",
        NotArchived: "Не заархивированный",
        Description: "Описание",
        File: "Файл",
        Created: "Время создания",
        Updated: "Время редактирования",
        Creator: "Создатель",
        Where: "Адрес создания",
        FileDrag: "Перетащите файл сюда или",
        FileSelect: "Выберите файл",
        MaxFile: "Максимальный размер загружаемого файла",
        Tabs: {
          Comments: "Примечания",
          History: "История",
          StatusHistory: "История Статуса",
        },
        WriteComment: "Написать комментарий",
        Edit: "Изменить",
        Assigners: "Исполнители",
        NotFound: "Информация не найдена!",
        Confirmation:{
          message:"Статус приложения",
          from:"от",
          to:"к",
          question:"ты  хочешь измениться"
        },
        HasFile:{
          No:"Файл не существует",
          Yes:"Файл существует"
        },
        EmpthyDescription:"Добавьте подробное описание...",
        Ticket:'Обращаться',
        Create:"был создан",
        Update:"был изменен",
        EmpthyValue:"Пустое значение",
        CreateFileName:"Имя созданного файла",
        DeletedFileName:"Имя удаленного файла",
        AssignUser:"Прикрепленный исполнитель",
        UnAssignUser:"Отмененный исполнитель"
      },
      CallCenter: {
        TableHeader: {
          QueueCalls: "Поступающие",
          TableHistory: "История звонков",
          CallForward: "Переадресованные звонки",
          TableRecording: "Записанные звонки",
          Type: "Тип",
          Status: "Статус",
          From: "От кого",
          ToOperator: "Кому",
          To: "Оператор",
          Via: "Телефон",
          CreatedAt: "Время создания",
          End: "Время окончания",
          Time: "Время",
          Audio: "Запись",
          Tap: "Оператор",
          via: "телефон",
          ContactTime: "Время связи",
          WaitingTime: "Время ожидания",
          TotalWaitingTime: "Общее время ожидания",
          TotalDurationTime: "Общее время общения",
          CustomerPhoneNumber: "Номер телефона клиента",
          Language: "Язык",
          Filters: "Фильтр",
          FromOperatorSip: "Номер оператора переадресации SIP",
          ToOperatorSip: "SIP номер оператора, который получил",
          Incoming: "Входящий",
          Outgoing: "Исходящий",
          IncomingCalls: "Входящие звонки",
          OutgoingCalls: "Исходящие звонки",
          Answered: "Ответил",
          NotAnswered: "Не ответил",
          Missed:"Пропущено",
          MissedCallCount:"Пропущенные",
          HungUp:"Выпущенный",
          CallType: "Тип вызова",
          CallStatus: "Статус звонка",
          MissedCall: "Пропущенный номер вызова",
          LastCall: "Последний номер звонка",
          Interval:"Интервал",
          SipNumber:"SIP-номер",
          IVRYes:"Потребители, использующие IVR",
          IVRNo:"Потребители, которые не используют IVR",
          IVR:"IVR",
          Call:"Вызов",
          AllTime:"Все периоды",
          AllTimeSee:"Посмотреть все периоды",
          ViewEvaluation: "Оценка без привязки к потребителю",
          AccountNumber: "Номер счёта",
          ConsumerType: "Тип потребителя",
          HOUSEHOLD: "Бытовой",
          LEGAL: "Юридический",
          ConsumersReport: "Отчёт потребителей",
          LiveStreamingAllowed:"Видеонаблюдение",
          VideoRecord:"Видеотексты",
          StartTime:"Время начала",
          Link:"Ссылка",
          Record:"Записи",
          UzbRusKk:"Узбекский | Русский | Каракалпакский",
          DurationTotalAvg:"Продолжительность(общая|средняя)",
          WorkPercent:"Процент работы"
        },
        TableUserID: {
          ReqType: {
            Title: "Тип заявки",
            Request: "Запрос",
            Complaint: "Жалоба",
            Offer: "Предложение",
            Service: "Предоставление услуг",
          },
          UserType: {
            Title: "Лояльность клиент",
            Well: "Хороший",
            Medium: "Средний",
            Rude: "Гнусный",
            VeryBad: "Очень плохой",
            Lose: "Hеправильный номер",
          },
          UserData: "Данные Клиента",
          Description: "Примечание",
          SubmitBtn: "Отправить",
          CallHistory: "История звонков",
          UserAngryTitle: "Вспыльчивый",
        },
        Call: {
          TableHeader: {
            Phone: "Номер телефона",
            FISH: "ФИО потребителя",
            oneName: "Имя",
            Type: "Тип потребителя",
            Fio: "Ф.И.О",
            Address: "Адрес",
            PersonalAccount: "Лицевой счет",
            CounterNumber: "Номер счетчика",
            CounterInformation: "Информация о счетчике",
            InterruptStartTime: "Время начало прерывания",
            InterruptEndTime: "Время окончания прерывания",
          },
          Status: {
            CounterStatus: "Статус счетчика",
            Status: "Состояние счетчика",
            StateBalance: "Состояние баланса",
            OtherProblems: "Другие проблемы",
            SubStatus: {
              Good: "Состояние хорошее",
              Debt: "Имеется задолженность",
              Repair: "Ремонт продолжается",
              Bad:"Плохое состояние",
              Unknown:"Неизвестный"
            },
          },
          CounterStatus: {
            Launch: "Запуск",
            Loading: "Загрузка",
            On: "Включенный",
            Off: "Выключен",
            OnBtn: "Включить",
            OffBtn: "Oтключить",
            CountersStatus: "Счетчики",
            ChangeCountersStatus: "Изменение состояния счетчиков",
            LaunchToastText: "Успешно запущен",
            Error400Text: "Счетчик можно перезагружать 1 раз в 10 минут",
            AllBalance: "Общий баланс:"
        },
          UserSearch: "Поиск пользователя",
          UserInfo: "Данные потребителя",
          ConsumerType: "Тип потребителя",
          ConsumerName: "Информация потребителя",
          HomePhone: "Домашний телефон",
          PassportNumber: "Номер паспорта",
          PassportSerial: "Док. серия и №",
          SolveProblem: "Перейти для устранения проблем:",
          History: "История обращения",
          FeedbackAboutClient: "Отзыв о клиенте",
          TotalItemsSelected: "Выбрано { count } данных",
          Incident: "Инцидент",
          All: "Всего",
          Electr: "Электроэнергия",
          ActViolation: "Акт нарушения",
          Fine: "Пеня",
          Reconnection: "Повторное подключение",
          WithoutContract: "Безучетное потребление",
          Reactive: "Реактив",
          Bust: "Перебор",
          Shortage: "Недобор",
          Services: "Услуги",
        },
        Title: "Call Center",
        OperatorIsReady: "Ответить!",
        WaitingClientsNotFound: "Поступающих клиентов нет",
        CountOfClientsWaiting: "{{count}} клиентов ждут ответа оператора",
        CallConnectedSuccessfully: "Соединение успешно установлено!",
        CallEnded: "Звонок окончен!",
        SocketConnected: "Соединение с сервером успешно установлено!",
        SocketError: "Произошла ошибка. Пожалуйста, обновите страницу и повторите попытку.",
        SocketClosed: "Соединение с сервером потеряно!",
        NotConnect: "Не подключился. Пожалуйста, повторите попытку позже",
        ConfirmSpyCall: "Подтвердить отслеживание звонков",
        ConfirmSpyCallMessage: "Вы уверены, что хотите отслеживать этот звонок?",
        WaitingConsumersCount: "Очередь звонков",
        CallIsNotAvailable: "У вас нет звонка",
        AttachCallToConsumerSuccessfull: "Вызов успешно прикреплен к клиенту",
        MobilePhoneEdited: "Мобильный телефон изменен успешно",
        AttachCallToConsumer: "Прикрепить звонок",
        CallEvaluation: "Оценка звонка",
        AttachCallToConsumerConfirm: "Вы действительно хотите прикрепить текущий звонок к этому потребителю?",
        UserEndedCall: "Звонок завершен",
        MakeCall: "Звонок",
        ToastCalled: "Звонок сделан!",
        OutgoingCalls: "Исходящий вызов",
        OutgoingCall: "Исходящий",
        Operator: "Оператор",
        ToAllOperators: "Всем операторам",
        CallPrefix:{
          Region: "Oбласть",
          Prefix:"Префиксы"
        },
        SpyReport:{
          Title:"Шпионский отчет",
          TableHeader: {
            Name: "Имя",
            Email: "Электронная почта",
            CreatedAt: "Время создания",
            StartSpy:"Время начала",
            EndSpy:"Время окончания"
          },
        },
        Attention:"Внимание",
        DeleteChannelTitle:"Нажмите кнопку «Удалить», чтобы сбросить все каналы вызовов.",
        Mention:"Напоминание",
        MentionAction:"Напоминание: Все ваши действия отслеживаются.",
        NoLiveVideo:"Живое видео недоступно",
        LiveVideoActive:"Живое видео активно",
        LiveVideoError:"Живое видео в настоящее время неактивно. Пожалуйста, обновите страницу",
        LiveVideoServerError:"Ошибка подключения к серверу во время потоковой передачи видео."
      },
      Boards: {
        title: "Обращения",
        Filter: {
          Search: "Поиск",
          IgnoreResolved: "Невыполненые задачи",
          OnlyMyIssue: "Только мои задачи",
          ResetAll: "Сбросить",
          User: "Пользователь",
        },
        TableHeader: {
          Backlog: "Организовано",
          Selected: "В процессе",
          InProgress: "Проверено",
          Done: "Выполнено",
        },
      },
      TicketTypes: {
        Title: "Типы обращений",
        TableHeader: {
          Name: "Название",
          NameUz: "Имя_Уз",
          NameRu: "Имя_Ru",
          NameKk: "Имя_Kk",
          NameCrl: "Имя_Krl",
          Color: "Цвет",
          Icon: "Знак",
          Status: "Статус",
        },
      },
      TicketStatus: {
        Title: "Типы статуса",
        TableHeader: {
          Name: "Название",
          Color: "Цвет",
          Order: "Порядковый номер",
          Status: "Статус",
        },
      },
      TicketPriorities: {
        Title: "Типы приоритетов",
        TableHeader: {
          Name: "Название",
          Color: "Цвет",
          Status: "Статус",
        },
      },
      Activities: {
        Title: "Виды деятельности",
        TableHeader: {
          Name: "Название",
          Description: "Описание",
          CreatedAt: "Время создания",
        },
      },
      Reports: {
        Excel: "Excel",
        TimeBy: {
          Title: "Отчеты о длительности звонков",
          TableHeader: {
            Time: "Время",
            AllCall: "Все звонки",
            DoneCall: "Количество решенных вызовов",
            DonePercentage: "Вызовы решены (в процентах)",
          },
        },
        RegionBy: {
          Title: "Отчеты по регионам",
        },
        OperatorBy: {
          Title: "Отчет о работе операторов",
        },
        Bot:{
          Title:"Отчеты от бота",
          TableHeader: {
            Name: "ФИО потребителя",
            Account_Type:"Тип потребителя",
            Account_Number:"Номер счета",
            Coato:"COATO",
            Count: "Количество запросов",
            Last_Request:"Время последнего опроса"
          },
        }
      },
      SipNumber:{
        Title:"SIP-номера",
        TableHeader: {
          Phone: "Число",
          Parol: "Пароль",
          Status: "Статус",
          CreatedAt: "Время создания",
        },
      },
      Operators: {
        BindWorkBreakTimeToOperators: 'Связать время работы и перерывов'
      }
    },

    Errors: {
      Required: "Обязательное поле",
      ErrorPastDate: "Прошедшая дата",
      ErrorElapsedTime: "Прошедшее время",
      ErrChooseFuture: "Выберите будущее",
      ErrorOfflineText: "Подключиться к Интернету",
      ErrorOfflineTitle: "Вы не в сети. Проверьте соединение",
      MaxLengthError: 'Максимальная длина: {{ value }} | Сейчас: {{ controlValueLength }}'
    },

    ERROR_LIST: {
      POPUP_RESTRICTED: "Вам не разрешено открывать страницу печати в вашем браузере!",
      SERVER_ERROR: "Ошибка сервера!",
      ACCESS_DENIED: "Доступ ограничен!",
      BAD_REQUEST: "Неправильный запрос!",
      FIELD_IS_REQUIRED: "Обязательное поле",
      INVALID_EMAIL_ADDRESS: "Неверный адрес электронной почты",
      MINLENGTH_REQUIRED: "Поле должно иметь не менее {{requiredLength}} символов",
      MAXLENGTH_REQUIRED: "Поле должно иметь не более {{requiredLength}} символов",
      MIN_REQUIRED: "Длина не должна быть меньше {{min}}",
      MAX_REQUIRED: "Длина не должна быть больше {{max}}",
      PATTERN_REQUIRED: "Поле должно содержать эти символы {{requiredPattern}}",
      BETWEEN: "Значение должно быт между {{x}} и {{y}}",
      INVALID_LENGTH: "INN должен быт между {{minLength}} и {{maxLength}}",
      IP_NOT_VALID: "Указан неправильный IP-адрес",
      CONFIRM_PASSWORD_ERROR: "Произошла ошибка при подтверждении пароля!",
      NUMERIC: "В этом поле пишутся только цифры!",
      LOWERCASE: "В этом поле пишутся только строчные буквы!",
      UPPERCASE: "В этом поле пишутся только заглавные буквы!",
      MAX_FILE_SIZE: "Размер файла не должен превышать {{size}}",
      TWO_Date_Error: "Вы должны выбрать 2 даты!",
      DATE_EMPYTE: "Поле не должно быть пустым!",
      ERROR_RESPONSE: "Возвращены неверные данные!(422)",
      ERROR_ACCESS:"Логин или пароль неверный",
      NTF_CANNOT_EDIT: "Уведомления этого типа нельзя редактировать",
      COATO_REQUIRED: 'Выбор региона или района обязателен!',
      COATO_REQUIRED_TEXT: 'Пожалуйста, выберите район или город!'
    },

    TOAST: {
      TITLE: {
        SUCCESS: "Успешно",
        CANCELLED: "Отменено",
        WARNING: "Предупреждение",
        ERROR: "Ошибка",
        INFO: "Информация",
        DOWNLOAD: "Загрузка",
        EXPORT_EXCEL: "Файл Excel успешно загружен!",
        IMPORT_EXCEL: "Файл Excel успешно импортирован!",
        RESIDENT_ID: "Данная строка таблицы не имеет абонента ID",
      },
      TOAST_DELETE: "Выбранная строка таблицы удалена успешно!",
      TOAST_SELECT_ROW: "Пожалуйста, выберите строку!",
      TOAST_SUCCESS: "Информация успешно добавлена!",
      TOAST_UPDATE: "Информация успешно отредактирована!",
      TOAST_STATUS_UPDATE: "Статус заявки успешно изменен",
      TOAST_ADD_COATO: "Выберите COATO, чтобы добавить ссылку.",
      TOAST_MULTIPLE_SELECT_ROW: "Пожалуйста, выберите строки!",
      TOAST_WARNING: "Если вы не знаете, как выглядит файловая структура, вы можете скачать образец файла.",
      TOAST_WARNING_RESIDENT: "Этот абонент не является сотрудником предприятия!",
      TOAST_ERROR_NOT_EMPLOYEE: "Этот абонент не является сотрудником!",
      TOAST_WARNING_RESIDENT_SEARCH: "Абонент не найден",
      SUCCES_ACCESS:"Авторизуйтесь успешно!",
      SUCCESS_NO_ACCESS: 'У вас нет доступ на использование системы!',
      ThisEmployeeIDDoesNotExist: "Этот идентификатор сотрудника не существует. Для просмотра журнала аутентификации требуется идентификатор сотрудника!",
      DoubleIncident:"Вы не можете указать больше двух!",
      MinCalendar:"Выберите хотя бы один день, чтобы добавить рабочее время",
      CameraNotAllowed:"Доступ к камере запрещен. Настройте камеру!"
    },

    MANAGEMENT: {
      TABLE_HEADERS: {
        NUMBERS: "№",
        ID: "ID",
        NAME: "Название",
        COMPANY: "Компании",
        DATE1: "03.2023",
        DATE2: "04.2023",
        DATE3: "05.2023",
        DATE4: "06.2023",
        DATE5: "07.2023",
        DATE6: "08.2023",
        DATE7: "09.2023",
        ACTION: "Действие",
        ACTION_LINK: "Переход",
      },
      USER_ROLE: {
        TABLE_HEADERS: {
          ID: "ID",
          PREFIX: "Префикс роли",
          NAME: "Название роли",
          LEVEL: "Уровень",
          TYPE: "Тип",
          COMMENT: "Комментарий",
          COMMENT_UZ: "Комментарий (УЗ)",
          COMMENT_CRL: "Комментарий (УЗ)",
          COMMENT_RU: "Комментарий (РУ)",
        },
        FILTER: {
          FILTER_BUTTON: "Доступные действия",
          TITLE: "Разрешенные действия",
        },
        INSERT_MODAL: "Добавление новой роли",
        UPDATE_MODAL: "Редактирование ролей",
        DELETE_MODAL: {
          TITLE: "Удаление роли",
          TEXT: "Вы действительно хотите удалить выбранную роль из системы?",
        },
        TOAST_EXISTS_ROLENAME: "Это «Имя роли» существует в системе.\nПожалуйста, введите другое имя!"
      },
      USERS: {
        ROLE: "Роль",
        ADD_MODAL_TITLE: "Добавление пользователя",
        EDIT_MODAL_TITLE: "Редактирование информации о пользователе",
        EDIT_USER: "Редактировать данные",
        UPDATE_STATUS_MODAL_TITLE: "Статус пользователя",
        UPDATE_PASS_MODAL_TITLE: "Изменить пароль пользователя",
        UPDATE_STATUS: "Изменить статус",
        UPDATE_PASS: "Изменить пароль",
        NEW_PASSWORD: "Новый пароль",
        NEW_PASSWORD2: "Повторно введите пароль",
        ACTIVE: "Активный",
        IN_ACTIVE: "Не активный",
        TOAST_EXISTS_LOGIN: "Данное имя «Логин» уже существует в системе.\nПожалуйста, введите другое имя!",
        RESPUBLIC: "Республика",
      },
      COMPANY_OPERATIONS: {
        TABLE_HEADERS: {
          ID: "ID",
          NAME: "Название",
          T_PERIOD: "Тек. период",
          ADDRESS: "Адрес",
          PHONE: "Телефон",
          TASK: "Задания",
          START_DATE: "Начало",
          END_DATE: "Окончание",
          STATUS: "Статус",
          RESULT: "Результат",
          KOD: "Код",
          ACTION: "Действие",
        },
        SELECT: {
          REGION: "Выберите регион",
          DISTRICT: "Выберите район",
          YEAR: "Год",
          MONTH: "Месяц",
        },
        MODAL: {
          TITLE: "Перерасчет текущего месяца",
          TEXT: "Вы действительно хотите одновременно перерасчитать, а затем перевести данную компанию на новый период?",
          SAVE: "Да выполнить",
        },
      },
    },

    ChatService: {
      Search: "Поиск...",
      Chats: "Чаты",
      Select_chat: "Выбрать чат",
      About: "обо мне",
      Personal_information: "Персональные данные",
      View_contact: "Посмотреть контакт",
      Mute_notifications: "Выключить уведомления",
      Block_contact: "Блокировка контакта",
      Clear_chat: "Очищение истории",
      Write_message: "Напишите свое сообщение",
      Send: "Отправить",
    },

    ShortRegions: {
      A03000: "Анд...",
      A06000: "Бух...",
      A08000: "Джи...",
      A10000: "Каш...",
      A12000: "Нав...",
      A14000: "Нам...",
      A18000: "Сам...",
      A22000: "Сур...",
      A24000: "Сыр...",
      A30000: "Фер...",
      A33000: "Хор...",
      A27000: "Таш об...",
      A26000: "Таш г... ",
      A35000: "Қора Рес...",
      A00002: "Хор Ург...",
    },

    ShortRegionsCode: {
      A03000: "Aнд...",
      A06000: "Бух...",
      A08000: "Жиз...",
      A10000: "Қаш...",
      A12000: "Нав...",
      A14000: "Нам...",
      A18000: "Сам...",
      A22000: "Сур...",
      A24000: "Сир...",
      A30000: "Фар...",
      A33000: "Хор...",
      A27000: "Тош об...",
      A26000: "Тош г...",
      A35000: "Қора Рес...",
      A00002: "Хор Ург...",
    },

    FullRegions: {
      Andijan: "Андижан",
      Bukhara: "Бухара",
      Jizzakh: "Джизак",
      Kashkadarya: "Кашкадарья",
      Navoi: "Навои",
      Namangan: "Наманган",
      Samarkand: "Самарканд",
      Surkhandarya: "Сурхандарьинская",
      SyrDarya: "Сырдарья",
      Ferghana: "Фергана",
      Khorezm: "Хорезм",
      TashkentRegion: "Ташкентская область",
      TashkentCity: "Город Ташкент",
      KKR: "KKR",
    },

    Profil: {
      Name: "Профиль",
      Request: "Запросы",
      Complaint: "Жалобы",
      CallTime: "Общее время разговора",
      CallName: "Количество звонков",
      Offer: "Предложения",
      Service: "Предоставление услуг",
      Chat_title: "Количество ежедневных полученных заявок",
      days30Statistics: "Статистика звонков за последние 30 дней",
      days30StatisticsTime: "Общее время разговора 30 дней",
      ImgError: "Размер изображения должен быть меньше 2 МБ.",
      EditProfileMessage: "Информация изменена!",
      FirstAndSecondName: "Имя Фамилия",
      respublika: "Республика",
      region: "Область",
      tuman: "Район",
      kompaniya: "Компания",
      inspektor: "Инспектор",
      operator: "Оператор",
      resident: "Резидент",
      Months: {
        January: "Январь",
        February: "Февраль",
        March: "Март",
        April: "Апрель",
        May: "Май",
        June: "Июнь",
        July: "Июл",
        August: "Август",
        September: "Сентябрь",
        October: "Октябрь",
        November: "Ноябрь",
        December: "Декабрь",

        INVALID_MONTH: "Неверный месяц"
      },
    },

    Selects: {
      Regions: "Регион",
      Region: "Регион",
      FromRegion: "От Регион",
      ToRegion: "В регион",
      PES: "Cписок ПЕС",
      RES: "Cписок РЕС",
    },

    NOTIFICATION_TYPE: {
      MAINTENANCE: "Уведомление об отключениях",
      20: "Другие типы сообщений",
    },

    Notification: {
      Notifications: "Уведомления",
      NoNotifications: "Нет непрочитанных уведомлений",
      AllNotifications: "Все Уведомления",
      AddNotification: "Отправить уведомление",
      New: "Новый",
      StartDate: "Дата начала",
      EndDate: "Дата окончания",
      StartTime: "Время начала",
      EndTime: "Время окончания",
      MessageDescription: "Содержимое сообщения",
      SelectAll: "Выбираем все",
      SelectElementName: "Выбрано",
      SelectElementEnd: "регионов",
      SelectUserEnd: "пользователей",
      MessageFull: "Подробное описание сообщения",
      MessageSend: "Отправить",
      SendName: "Отправить уведомление",
      NotFound: "Не найдено",
      MessageType: "Тип сообщения",
      SelectSendUser: "Пользователь для отправки сообщения",
      MessageDescriptionUZ: "Содержимое сообщения(УЗ)",
      MessageDescriptionRU: "Содержимое сообщения(RU)",
      MessageFullUZ: "Подробное описание сообщения(УЗ)",
      MessageFullRU: "Подробное описание сообщения (RU)",
      Consumer: "Потребитель",
      AccordingtoCOATO: "Сообщить о сбоях в COATO",
      SendMsgToCustomer: "Отправить сообщение клиенту",
      TYPE_MAINTENANCE: "Уведомление об отключениях",
      TYPE_NOTIFICATION_FROM_CRM: "Другие типы сообщений",
      LoadingProgress: "Отправка...",
      Responsed: "Следующее сообщение было отправлено",
      Error: "Ошибка",
      SelectDistrictCOATO: "Выберите район COATO!",
      Sent: "Отправил",
      Edited: "Отредактировано",
      FileUploadText: "Данные в файле будут проверены по заданной структуре," +
      "\n результат возвращается в виде уведомления",
    },

    CallCenter: {
      last3day: "Последние 3 дня",
      last7day: "Последняя 1 неделя",
      last15day: "Последние 15 дней",
      last1month: "Последний 1 месяц",
      last3months: "Последние 3 месяца",

      SelectPeriod: "Выберите период",
      TotalNumberOfCalls: "Общее количество звонков",
      AllowedAppeals: "Обращения",
      Completed: "Завершенный",
      InProgress: "В процессе",
      AverageTalkTime: "Среднее время разговора",
      AllTalkTime: "Общее время разговора",
      AverageWaitingTime: "Среднее время ожидания",
      CaseStatusStatistics: "Статистика статуса обращений",
      ListLastCalls: "Список последних звонков",
      Consumer: "Потребитель",
      Phone: "Номер телефона",
      WaitingTime: "Время ожидания",
      Status: "Состояние",
      Time: "Время",
      CalculationStatus: "Статус расчета",
      Accepted: "Принял",
      Skipped: "Пропущено",
      TalkedStatus: "Разговаривал",
      NotTalkedStatus: "Неразговаривал",
      firstRegionsName: "Выбрано",
      secondRegionsName: "региона",
      DuringConversation: "В режиме разговора",
      ActiveCall:"Активные звонки",
      ActiveOperators:"Активные операторы",
      Online:"в сети",
      Offline:"oфлайн",
      QueueCall:"Очередь вызовов",
      CallCount:"Всего звонков",
      NotForwardCallCount:"Ненаправленные звонки",
      ForwardCallCount:"Переадресованные звонки"
    },

    ROLES: {
      RepublicAdmin: "Республиканский администратор",
      RegionAdmin: "Региональный администратор",
      DistrictAdmin: "Администратор района",
      CompanyAdmin: "Администратор компании",
      Inspector: "Инспектор",
      Operator: "Оператор",
      Resident: "Резидент",
      NameofROL: "Роль",
      DEPARTMENT_HEAD: "Начальник отдела",
      ADMIN: "Админ",
      OPERATOR: "Оператор",
      AGENT: "Агент",
      PERFORMER: "Испольнитель"
    },

    LEVELS: {
      Republic: "Республика",
      Region: "Область",
      District: "Район",
      Company: "Компания",
      Inspector: "Инспектор",
      Operator: "Оператор",
      Resident: "Резидент",
    },

    PageTitle: {
      Home: "Главная",
    },

    ChangeMenu: {
      Mega_Menu: "Большое верхнее меню",
      Dropdown_Menu: "Маленькое верхнее меню",
    },

    Settings: {
      Name: "Настройки",
    },

    Date: {
      From: "Oт",
      To: "До",
      StartWork: "Начало работы",
      EndWork: "Окончание работы",
      Empty: "Ничего не найдено",
      NotTypes: "Типы запросов недоступны",
    },

    Button: {
      Search: "Искать",
      Clear: "Очистить",
      Accept: "Принять",
      Reject: "Отклонить",
      Disable: "Отключить",
      Export: 'Экспорт',
      ExportExcel: 'Экспорт в Excel',
      RegionOrDistrict: 'Регион / Район',
    },

    AccountType: {
      ELECTRICITY: "Электроэнергия",
      REACTIVE: "Реактив",
      BUST: "Перебор",
      FINE: "Пеня",
      COLLECTION: "Недобор",
      SERVICES: "Службы",
      ECO_ENERGY: "Эко Энергии (-А)",
      TRANSPORTATION: "Услуга(за транспортировку)",
    },

    AvailabilityofReservation: "Доступность резервирования",
    TotalCost: "Общая стоимость (сумма)",
    AnnualVolumeUnderAgreement: 'Годовой объем по договору (тысяч кВтч)',
    ReportSubmission: 'Дата подачи отчета',
    PrepaymentPercentage: 'Процент предоплаты',
    SDATOLegalLocation: "Юридическое местоположение SOATO",
    ParentOrganization: "Родительская организация",
    SDATOLocation: 'Местоположение SOATO',
    Fine: "Пеня%",
    PowerandAmount: "Количество мощности и энергии",
    MainTariff: "Основная Тарифная Группа",
    AdditionalCode1: "Дополнительный Код1",
    AdditionalCode2: "Дополнительный Код2",
    AdditionalCode3: "Дополнительный Код3",
    InstalledPower: "Установленная мощность",
    Substation: "Вещество",
    TransformerName: 'Имя трансформатора',
    TP_Feeder: 'TП + Фидер',
    FullNameForeman: "Бригадир Ф.И.O",
    FullNameController: 'Контроллер Ф.И.O',
    CodeBook: "Книга кодов",
    BooksPage: "Страница книги",
    ConnectionTo: 'Соединение с АСКУЭ',
    SubtractedFrom: 'Вычитается',
    Consumer: "Потребитель",
    Subconsumer: "Субпотребитель",
    FromtheTariff: 'из тарифа',
    FullView: "Полный просмотр",
    View: "Просмотр",
    Bind: 'Связывать',
    BindWorkTime: 'Связывать рабочее время',
    BindBreakTime: 'Связывать время перерывов',
    AddBindWorkTime: 'Добавить рабочее время',
    AddBindBreakTime: 'Добавить время перерывов',
    Edit: "Изменить",
    Delete: "Удалить",
    Spy: "Шпионить",
    Login: "Войти",
    Password: "Пароль",
    Logout: "Выйти",
    Page: "Страница",
    LoginSystem: "Авторизоваться",
    LEGAL_ENTITY_DATA: "Данные о юридическом лице",
    BILLING_MONTH_RECALCULATION_NOT_INCLUDED_DEBITS: "Перерасчеты расчетного месяца, не входящие в дебет",
    RETURN_PREPAYMENT: "Возврат предоплаты",
    RECALCULATE_OTHER_REASON: "Перерасчеты др. причин",
    ABOVE_THE_TARIFF_FOR_EXCEEDING_THE_CONTRACTUAL_VALUE: "Сверх тарифа за превышение договорной величины",
    FOR_KWTH: "за кВтч",
    SUM: "Количество",
    INCOMING_BALANCE: "Сальдо входящее",
    ACCRUED: "Начислено",
    ACT_RECALCULATION: "в.т.ч. Акт перерасчета",
    PAYMENT: "Оплата",
    BALANCE_OUT: "Исходящее сальдо",
    V_T_CH_FOUND_PAYMENT: "в.т.ч. Найденная оплата",
    DECOMMISSIONED: "Списано",
    CLASSIFIED_HOPELESS: "Сальдо",
    RESTORED_ACCOUNTS_RECEIVABLE: "Оплата след. месяца",
    OUTGOING_BALANCE: "Сальдо исходящее",
    CURRENT_PAYMENT: "Оплата текущая",
    NEXT_MONTH_PAYMENT: "Оплата след. месяца",
    BALANCE: "Баланс",
    TOTAL: "Всего",
    V_T_CH_ACT_RECALCULATION: "в.т.ч. Акт перерасчета",
    BALANCE_SALDO: "Сальдо",
    CONSUMER_CODE: "Код потребителя",
    GOVERNMENT_ORGANIZATION: "СООГУ",
    CONSUMER_NAME: "Ф.И.О потребителя",
    CONTRACT_VALUE: "Договорная величина (кВтч)",
    OVERDUE_AMOUNT: "Просроченная сумма",
    AFFILIATION: "Принадлежность",
    PREPAYMENT_PERCENTAGE: "Процент предоплаты",
    REPORT_DATE: "Дата отчёта",
    TARIFF_GROUP: "Тарифная группа",
    CONTRACT_DATE: "Дата договора",
    INSTALLED_CAPACITY: "Установленная мощность",
    ADDRESS: "Адрес",
    PAYMENT_CODE: "Код оплаты",
    REPORT_NUMBER: "№ отчета",
    RESIDENT: "Резидент",
    ConsumerCard: "Потребительская карта",
    ConsumerCardHistory: "История",
    PERSONAL_CONSUMER_CARD: "Лицевая карта потребителя",
    PERSONAL_ACCOUNT: "Номер лицевого счета",
    DISTRICT_LOCATION: "Местоположение",
    Neighborhood: "Махалля",
    NOTES: "Примечание",
    METER_NUMBER: "№ счетчика",
    SHUTDOWN_DATE: "Дата отключения",
    INDICATION_INSTALLING_METER: "Показание при установке счетчика",
    DATE_INSTALLATION_METER: "Дата установки счётчика",
    COUNTER_STATUS: "Состояние счетчика",
    METER_TYPE: "Тип счетчика",
    CAPACITY: "Разрядность",
    TRANSFORMATION_KOEFF_KT: "Коэф. трансф КТ",
    ASKUE: "АСКУЭ",
    SOLAR_PANEL: 'Сол. панель',
    TARIFF_SUM: "Тариф(сум)",
    SUMNAME: 'сум',
    LAST_BYPASS: "Последний обход",
    LAST_BYPASS_DATE: "Дата последнего обхода",
    SEAL: "Сертификат",
    SEAL_NUMBER: "Сертификат №",
    DATE: "Дата",
    DEADLINE_TO: "Срок до",
    ACT_INVERTORY: "Акт перерасчета(Инвентаризация)",
    READING: "Показание",
    PENALTY_AMOUNT: "Сумма копеек",
    AMOUNT_PER_EP_E: "Сумма по эл/э",
    ACT_AMOUNT: "Сумма по акту",
    TOTAL_AMOUNT: "Общая сумма",
    RECOIL_INDICATION_MINUS_A: "Показание отдачи (-A)",
    INCOMING_BALANCE_KWTH: "Сальдо входящее кВтч",
    INCOMING_BALANCE_SUM: "Сальдо входящее сумма",
    CALCULATION: "Расчет",
    CALCULATION_TYPE: "Вид расчёта",
    CONTRACT_VALUE_KWH: "Дог. вел. (кВтч)",
    AVERAGE_DAILY_CONSUMPTION_KWH: "Ср. сут. расход(кВтч)",
    AVERAGE_MONTHLY_CONSUMPTION_KWH: "Ср. мес. расход(кВтч)",
    ESTIMATED_READING_FOR_LAST_MONTH: "Рас. пкз. за прошлый месяц",
    ACCRUED_DAY: "Начислено(дней)",
    ACCRUED_SUM: "Начислено(сум)",
    ACCRUED_KWH: "Начислено(кВтч)",
    RECALCULATION_DAYS: "Перерасчет(дней)",
    RECALCULATION_KWH: "Перерасчет(кВтч)",
    RECALCULATION_BY_AVERAGE_DAILY: "Перерасчет по ср. сут.",
    PENALTY_CHARGED_SUM: "Начислена пеня(сум)",
    PENALTY_RECALCULATION: "Перерасчет по пени",
    ESTIMATED_INDICATION_CURRENT_MONTH: "Рас. пкз. текущего месяца",
    RECALCULATION: "Перерасчёт",
    PAYMENT_RECALCULATION: "Перерасчет оплаты",
    WRITE_OFF: "Списание",
    PAYMENT_CURRENT: "Текущая оплата",
    ELECTRICITY: "Электроэнергия",
    ACT_VIOLATED: "Акт нарушения",
    UNLEARNED_CONSUMPTION: "Безуч. потребления",
    FINE: "Пеня",
    POV_CONNECTIONS: "Пов. подключения",
    SelectRegion: "Выберите область",
    RECALCULATION_DATE: "Дата перерасчета",
    BYPASS_INDICATION_OF_RECALCULATION: "Показание обхода на момент перерасчета",
    BYPASS_INDICATION_OF_RECALCULATION_A_MINUS: "Показание обхода на момент перерасчета(-A)",
    ALL_AMOUNT: "Всего сумма",
    UNLEARNED_CONSUMPTION_LONG: "Безучетное  потребление",
    ACT_RECONNECTION: "Повторное подключение",
    ResDataInvalid: "Ошибка в данных, отправленных с сервера!",
    LAST_PAYMENT_SUM: "Последняя оплата (сум)",
    LAST_PAYMENT_DATE: "Дата последный оплаты",
    PREVIOUS_BYPASS: "Предыдущий обход",
    PREVIOUS_DATE_BYPASS: "Дата предыдущего обхода",
    LOSS_AMOUNT: "Величина потери",
    ONES_WITHOUT: "Онесено (без)",
    VOSSTA_DEB: "Восста (деб)",
    BUILDING_FLAT_NUMBER: "Дом / Квартира",
    METER: "Счётчик",
    INSTALLATION: "Установка",
    NUMBER_ICON: "№",
    FROM: "От",
    BEFORE: "До",
    INVENTORY: "Инвентаризация",
    PERIOD: "Период",
    PREVIOUS: "Было",
    CURRENT: "Стало",
    BALANCE_IN: "Входящее сальдо",
    START_DATE: "Начало",
    TERMINATION: "Конец",
    CALCULATION_CATEGORY: "Тип расчета",
    COUNT_DAY: "Кол-во дней",
    KVT_A_PLUS: "кВтч(А+)",
    KVT_A_MINUS: "кВтч(А-)",
    DIFFERENCE: "Разница",
    READING_A_PLUS: "Показание А+",
    READING_A_MINUS: "Показание А-",
    CURRENT_PERIOD_DATE: "Дата расчетного периода",
    AVERAGE_DAY_EXP: "Среднесуточный расход",
    AVERAGE_DAY: "Ср.сут",
    SETTLEMENT_DATE: "Дата расчета",
    READING_CALCULATION: "Расчетное показание",
    KWT: "кВт",
    LOSS: "Потеря",
    EDUCATION_PRICE: "Ценообразование",
    VIOLATION: "Нарушение",
    OTHER_CALCULATION: "Другие расчеты",
    BYPASS_HISTORY: "История обхода",
    PAYMENT_HISTORY: "История оплаты",
    BANK_DOCUMENT_TYPE: "Тип банковского документа",
    DOCUMENT_NUMBER: "Номер документа",
    ENTER_DATE: "Дата ввода",
    DOCUMENT_DATE: "Дата документа",
    PAYMENT_AMOUNT: "Сумма оплаты",
    PAYMENT_PURPOSES: "Назначение платежа",
    CREATED_BY: "Кем создан",
    UPDATED_BY: "Кем изменён",
    PREVIOUS_BYPASS_READING_AP: "Предыдущий обход показания (А+)",
    PREVIOUS_BYPASS_READING_AM: "Предыдущий обход показания (А-)",
    SN_METER: "SN счетчика",
    CONSUMPTION_PLUS: "Потребление (+А)",
    RECOIL_MINUS: "Отдача (-А)",
    BYPASS_DATE: "Дата обхода",
    SOURCE: "Источник",
    AGENT: "Агент",
    UPDATED_AT: "Дата изменения",
    LOGIN: "Логин",
    STATUS: "Состояние",
    PAYMENT_DATE: "Дата оплаты",
    TARIFF: "Тариф",
    PAYMENT_TYPE: "Вид платежа",
    CALCULATION_PERIOD: "Период расчета",
    SENDER: "Отправитель",
    EXTRA_OPTIONS: "Доп. параметры",
    SUMM: "Сумма",
    TOTALL: "Итого",
    NEGOTIABLE_CARD: "Оборотная карточка",
    SHOWCASE: "Витрина",
    DEBT_PAYMENT_BEFOREHAND_SHORT: "Задол.(-предоп.)",
    IncomingCallRestriction: "Разрешить входящие звонки",
    OutgoingCallRestriction: "Разрешить исходящие звонки",
    OutgoingCall: "Исходящий вызов",
    PleaseWait: "Подождите",

    FilesUploadedToGeoMap: "Файлы, загруженные на Геокарту",
    DownloadCurrentStatus: "Текущий статус загрузки",
    UploadNewFile: "Загрузить новый файл",
    UploadedByWho: "Загружено Кем",
    FileName: "Имя файла",
    RejectedFile: "Отклоненный",
    UploadTime: "Время загрузки",
    Explanation: "Объяснение",
    Downloaded: "Скачано",
    FileApproved: "Файл одобрен",
    FileDisapproved: "Файл не был проверен",
    Upload: "Загрузить",
    Uploading: "Загрузка",
    SelectCSVFile: "Выберите CSV-файл",
    CSVFileUploadNote: "Могут быть загружены только файлы в формате CSV. Размер файла не должен превышать 1 МБ.",
    CommentOptional: "Комментарий (необязательный)",
    ErrorCSVSelect: "Ошибка: Выберите файл CSV!",
    ErrorSizeLimit: "Ошибка: Размер файла превысил 1 МБ!",
    CallsForwardCount: "Количество вызовов, переадресованных на другой номер",
    IVRHistoryCount: "Количество пользователей, вошедших в систему IVR и получивших информацию",
    MissedCallsCount: "Количество пропущенных вызовов",
    AuthenticationLog: "Журнал аутентификации",
    Browser: 'Браузер',
    IP_adress: "IP-адрес",
    Location: "Местоположение(Location)",
    LoginAttemptTime: "Время попытки входа",
    RolePermissions: "Ролевые разрешения",
    ThisRolIdNotDef: "Эта роль IDsi не найдена!",
    Permissions: "Разрешения",
    PerToRole: "Разрешения, относящиеся к роли",
    Module: "Модуль",
    UpdateAt: "Время обновления",
    Confirm: "Подтвердить",
    ToBack: "Назад",
    confirmWarning: "Одтвердить изменения?",
    OperatorOnline: "Оператор активен",
    OperatorOffline: "Оператор неактивен",
    NotFoundCOATO: "СОАТО не найден!",
    NotBeenDefinedCOATO: "Не удалось найти список Coatos с доступом пользователя",
    CommunicationHasDeteriorated: "Качество связи ухудшилось!",
    NoSound: "Без Звука",
    ActivityStatus: "Состояние активности (вход в систему)",
    ConnectionStatus: "Состояние оператора (Онлайн или Офлайн)",
    Online: "Онлайн",
    Offline: "Офлайн",
    OffNotification: "Отключение уведомления",
    OnNotification: "Включение уведомления",
    NameOrEmailSearch: "Поиск по имени или E-mail",
    NameOrCoatoSearch: "Поиск по CAOTO или имени",
    OnSound: "Включить звук",
    CallForwarding: "Переадресация вызовов",
    WaitingClientsWindow: "Окно ожидающего клиента",
    CloseWindow: "Закрыть окно",
    OpenWindow: "Открыть окно",
    CustomerChat: "Разговор с клиентом",
    EndCall: "Завершить вызов",
    SureEndCall: "Вы действительно хотите закончить этот звонок?",
    Termination: "Конец",
    CallEnd: "Звонок окончен",
    ContactOperator: "Онлайн-чат с оператором",
    REN: "АО “РЕГИОНАЛЬНЫЕ ЭЛЕКТРИЧЕСКИЕ СЕТИ“",
    KVT_A: "кВтч",
    OldSumm: "Итого по старому",
    NewSumm: "Итого по новому",
    PaymentDate: "День оплаты",
    DateFrom: "Дата с",
    DateTo: "Дата по",
    Days: "Дни",
    RECALCULATION_NOT_FOUND: "У данного потребителя не найден перерасчет по пени",
    OPERATOR_STATISTICS: "Просмотр статистики операторов",
    AddPermitCoato: "Дополнительные пристроенные области",
    CoatoNotFoundToastTitle: "Не найден или вы ввели COATO, к которому у вас нет доступа",
    UuidOrStartNotFound: "Параметр UUID или Start отсутствует",
    RecordSuccessfullyDeleted: "Запись успешно удалена",
    FailedToDeleteRecord: "Не удалось удалить запись",
    DurationTime: "Продолжительность",
    ViewLink: "Просмотр",
    SentTime: "Время отправки",
    NotificationMonitoring: "Мониторинг уведомлений",
    NTFMonitoringTitle: "Обновите страницу, чтобы определить статус сообщения в реальном времени",
    ReadingTime: "Время чтения",
    SendEmployees: "Сотрудники, которым отправлено текущее сообщение",
    Read: "Прочитано",
    Unread: "Непрочитанный",
    FromNewMessage: " отправил вам новое сообщение",
    RestartAccountSipNumber: "Произошел сбой в сети. Перезапустите аккаунт и SIP номер."
  },
};
