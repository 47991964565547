@import '../../../scss/base/bootstrap-extended/include';
// Bootstrap includes
@import '../../../scss/base/components/include'; // Components includes

// To open dd on right
.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}


/* dropdown.component.css */
.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-item.active span {
  color: #fff !important;
}


.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
}

.dropdown-language .dropdown-menu {
  left: -58px !important;
}

.menu-content .dropdown-menu {
  left: -112px !important;
}

.dropdown-menu.show {
  display: block;
}

.operator-notification-dropdown {
  left: -130px !important;
}


// Cart Touchspin
.touchspin-cart {
  .touchspin-wrapper {
    width: 6.4rem;
  }

  &:focus-within {
    box-shadow: none !important;
  }
}

app-navbar-bookmark {
  display: flex;
}

// Apply style on window scroll for navbar static type
.navbar-static-style-on-scroll {
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 20px 0px !important;
}

@media (max-width: 480px) {
  .darkMode, .dropdown-language, .notification {
    display: none;
  }
}

// Dark Layout
.dark-layout {
  .navbar-container {
    .search-input {
      .search-list {
        li {
          &.auto-suggestion {
            &:hover {
              background-color: $theme-dark-body-bg;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .d-none-content {
    display: none !important;
  }
}

@media (max-width: 1319.98px) {
  .d-1xl-none-content {
    display: none !important;
  }
}

@media (max-width: 1390px) {
  .dropdown-content {
    column-gap: 1rem !important;
  }
}

@media (min-width: 1200px) and (max-width: 1539.98px) {
  .brand-text-content {
    display: none !important;
  }
}
