import {Component, inject, OnInit, signal} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {CoreMenuService} from '@core/components/core-menu/core-menu.service';
import {CoreConfigService} from '@core/services/config.service';
import {CallStatuses} from '@call-center/pages/consumers/call-statuses';
import {WebsocketService} from '@call-center/services/websocket.service';
import {AsyncPipe, CommonModule, NgClass} from '@angular/common';

import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ToastService} from '@core/services/toast.service';
import {CurrentCallDetailsService} from '@call-center/services/current-call-details.service';
import {SecondsConvertorService} from '@shared/services/seconds-convertor.service';
import WaveSurfer from 'wavesurfer.js';
import {AudioRecordService} from '@call-center/services/audio-record.service';
import {ModalService} from '@core/services/modal.service';
import {ForwardCallComponent} from '@call-center/components/forward-call/forward-call.component';
import {TooltipModule} from 'primeng/tooltip';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';
import {OutgoingCallComponent} from '../../components/outgoing-call/outgoing-call.component';
import {CALL_TYPE} from '@shared/helpers/module-types';
import {BehaviorSubject, interval} from 'rxjs';
import {DateTime} from 'luxon';
import {PhoneNumberPipe} from '@shared/pipes/phone-number.pipe';
import {AuthService} from '../../../pages/login/auth.service';
import {BadgeModule} from 'primeng/badge';
import {ConfirmationDialogService} from '@core/services/confirm-modal.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MeService} from '../../../pages/login/helpers/me.service';
import {LiveCallsDataService} from '@app/common/customer-evaluation/live-calls-data.service';
import {downloadMp3} from '@shared/utils/download-mp3';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';

@UntilDestroy()
@Component({
    templateUrl: 'main.components.html',
    styleUrl: 'main.component.scss',
  imports: [
    RouterOutlet,
    NgClass,
    TranslateModule,
    TooltipModule,
    ButtonComponent,
    AsyncPipe,
    PhoneNumberPipe,
    BadgeModule,
    CommonModule,
    KeenIconComponent
  ],
    providers: [WebsocketService]
})

export class MainComponent implements OnInit {

  waitingAudio = new Audio('assets/audio/waiting-ring.mp3');
  waitingConsumers = signal([]);
  public isWaitingsModalOpen = false;
  public isAnsweringModalOpen = false;
  public isHangUpModalOpen = false;
  public isCallMute = false;
  public isRecordAudioPlaying = false;
  public isRecordAudioModalOpen = false;
  public lastCallDetails = {time: null};
  public recordedAudioDuration = null;
  public recordedAudioCurrentTime = null;
  public recordedAudioUrl = null;
  public isPlay = signal(false);
  public isHasDataPlay = signal(false);
  public takePlay = interval(4000);
  public myRatingTimer: any;
  public seconds = 0;
  public minutes = 0;
  public hours = 0;
  public callDetails = null;
  callHideValue = signal(false);
  callHas = signal(false);
  waitingCount = 0;
  isDisabledBtn = signal(false);
  public callTimer = interval(500);
  isCallActive = signal(false);
  isCallTime = signal('00:00:00');
  public webSocket = inject(WebsocketService);
  public outgoing_type = new BehaviorSubject<boolean>(false);
  outgoingModalBtn = false;
  protected _outGoing: boolean;
  private dataKey = 'call-details';
  private http = inject(HttpClient);
  private toast = inject(ToastService);
  private translocoService = inject(TranslateService);
  private currentCallDetailsService = inject(CurrentCallDetailsService);
  private secondsConvertService = inject(SecondsConvertorService);
  private audioRecordService = inject(AudioRecordService);
  private _confirmModalService = inject(ConfirmationDialogService);
  private modalService = inject(ModalService);
  private _liceCallsDataService = inject(LiveCallsDataService);
  private wavesurfer: WaveSurfer;
  private currentUser: any;

  constructor(
    private coreMenuService: CoreMenuService,
    private coreConfigService: CoreConfigService,
    private _authenticationService: AuthService,
    private _meService: MeService
  ) {


    this._authenticationService.currentUser.subscribe(
      (x: any) => {
        this.currentUser = x;
        this.isPlay.set(x.ring_notify);
      }
    );

    if (JSON.parse(localStorage.getItem('rememberMenu')) == 'callCenter') {
      this._liceCallsDataService.sendVisibleData(true);
    } else {
      this._liceCallsDataService.sendVisibleData(false);
    }

    this.coreMenuService.setCurrentMenu('callCenter');
    this.coreConfigService.setTitle('Menu.CallCenter');


    this.webSocket.connect();

    this.webSocket.receivedMessage.subscribe(data => {
      if (data?.data?.status === CallStatuses.RINGING) {
        this.onRinging(data);
      }

      if (data?.data?.status === CallStatuses.ANSWERED) {
        this.callHas.set(true);

        this.onAnswered(data);
        if (data?.data?.type == CALL_TYPE.OUTGOING) {
          this.outgoing_type.next(true);
        } else if (data?.data?.type == CALL_TYPE.INCOMING) {
          this.outgoing_type.next(false);
        }
      }

      if (data?.data?.status === CallStatuses.HANGUP) {
        this.callHas.set(false);

        this.onHangUp(data);
      }

      if (data?.data?.status === CallStatuses.HANGUP_QUEUE) {
        this.getWaitingConsumers();
      }

    });

    this._outGoing = this.currentUser?.outgoing === true;


    this.takePlay
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.isPlay() && this.isHasDataPlay()) {
          this.startAudioBtnClicked();
        }
      });

    this.callTimer
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.isCallActive()) {
          this.updateTimer();
        }
      });
  }

  onMuteAudio(isBoolean: boolean) {
    if (isBoolean) {
      this.http.patch(environment.apiUrl + '/api/v1/user/update-ring-notify', {ring_notify: 1}).subscribe({
        next: (res: any) => {
          this.isPlay.set(res.data.ring_notify);
        },
        complete: () => {
          this._meService.loadMeData().subscribe();
        }

      });
    } else {
      this.http.patch(environment.apiUrl + '/api/v1/user/update-ring-notify', {ring_notify: 0}).subscribe({
        next: (res: any) => {
          this.isPlay.set(res.data.ring_notify);
        },
        complete: () => {
          this._meService.loadMeData().subscribe();
        }
      });
    }


  }

  ngOnInit(): void {
    this.audioRecordService.removeData();

    this.getWaitingConsumers();

    this.currentCallDetailsService.getData().subscribe((data: any) => {
      this.callDetails = data;

      if (data != null) {

        this.callHas.set(true);
        this.isAnsweringModalOpen = true;

        this.isWaitingsModalOpen = false;
        this.isCallActive.set(true);
      } else {
        this.isAnsweringModalOpen = false;
        this.callHas.set(false);
        this.isCallActive.set(false);
      }
    });

    this.playRecordedAudio();
  }
  refreshWaitingList(){
    this.getWaitingConsumers();
  }
  playRecordedAudio(): void {
    this.audioRecordService.getData().subscribe((data: any) => {
      if (data) {
        this.recordedAudioUrl = data.url;

        document.querySelector('.waveform').innerHTML = '';

        this.wavesurfer = WaveSurfer.create({
          container: document.querySelector('.waveform') as HTMLElement,
          waveColor: '#D1D9E4',
          progressColor: '#3686FF',
          height: 40,
          barWidth: 2,
          barGap: 2,
          barRadius: 50,
          url: data.url,
          dragToSeek: true,
          normalize: true
        });

        this.wavesurfer.on('ready', () => {
          this.recordedAudioDuration = this.secondsConvertService.convert(Math.round(this.wavesurfer.getDuration()));

          // this.wavesurfer.play();

          this.toggleRecordedAudio(true);
          this.audioRecordService.isLoadingStatus(true);
        });

        this.wavesurfer.on(('audioprocess'), () => {
          this.recordedAudioCurrentTime = this.secondsConvertService.convert(Math.round(this.wavesurfer.getCurrentTime()));
        });

        this.wavesurfer.on('finish', () => {
          this.toggleRecordedAudio(false);
          this.audioRecordService.isLoadingStatus(false);
        });

        this.toggleRecordedAudioModal(true);
      }
    });


  }

  toggleRecordedAudio(play: boolean) {
    this.isRecordAudioPlaying = play;

    if (play) {
      this.wavesurfer.play();
    } else {
      this.wavesurfer.pause();
    }
  }

  toggleRecordedAudioModal(open: boolean) {
    this.isRecordAudioModalOpen = open;

    if (!open) {
      if (this.wavesurfer.isPlaying()) {
        this.wavesurfer.stop();
        this.wavesurfer.destroy();
      }
      // this.wavesurfer.empty();
      this.isRecordAudioPlaying = false;
      this.audioRecordService.removeData();
      this.audioRecordService.isLoadingStatus(false);
    }
  }

  downloadRecordedAudio(): void {
    downloadMp3(this.recordedAudioUrl);
  }
  refreshLoading = signal(false);
  getWaitingConsumers(): void {
    const url = environment.apiUrl + '/call-center/api/v1/waiting-calls';
    this.refreshLoading.set(true);
    this.http.request('GET', url).subscribe((res: any) => {
      const myCall = sessionStorage.getItem(this.dataKey);
      const myCallDetails = myCall ? JSON.parse(myCall) : null;

      let newData = [];
      newData = res?.data;

      if (myCallDetails?.data?.call_id) {
        this.isHasDataPlay.set(false);
      } else if (res.success && newData.length > 0) {
        this.isHasDataPlay.set(true);
      } else {
        this.isHasDataPlay.set(false);
      }

      if (res.success) {
        this.waitingConsumers.set(res?.data);
        this.waitingCount = res?.data?.length;
      } else {
        this.waitingConsumers.set([]);
        this.waitingCount = 0;
      }
      this.refreshLoading.set(false)
    });
  }

  startAudioBtnClicked() {
    this.waitingAudio.play().then(() => {
    }).catch(error => {
      console.error('Audio play failed:', error);
    });
  }

  onRinging(data) {
    this.getWaitingConsumers();
  }

  onAnswered(data) {
    this.currentCallDetailsService.updateData({
      ...data,
      startTime: DateTime.now()
    });

    this.getWaitingConsumers();

    this.lastCallDetails = null;
  }

  onHangUp(data) {
    this.currentCallDetailsService.removeData();
    this.isCallActive.set(false);
    this.isCallTime.set('00:00:00');
    if (this.lastCallDetails && this.lastCallDetails.time) {
      this.lastCallDetails.time = this.secondsConvertService.convert(data?.data?.duration);
    }

    this.isHangUpModalOpen = true;

    setTimeout(() => {
      this.isHangUpModalOpen = false;
    }, 1500);

    this.getWaitingConsumers();
  }

  toggleWaitingsModal() {
    this.isWaitingsModalOpen = !this.isWaitingsModalOpen;
  }

  closeCallModal(event) {
    if (event) {
      this.callHideValue.set(false);
    } else {
      this.callHideValue.set(true);
    }
    this.isAnsweringModalOpen = event;
  }

  getWaitingListClass(): string {
    if (this.isWaitingsModalOpen && this.isAnsweringModalOpen) {
      return 'waiting-and-answering';
    } else if (this.isWaitingsModalOpen && !this.isAnsweringModalOpen) {
      if (this.callHas()) {
        return 'waiting-list-close';
      } else {
        return 'waiting-list-open';
      }
    } else {
      return 'waiting-list-close';
    }
  }

  operatorIsReady() {
    const url = environment.apiUrl + '/call-center/api/v1/answer';

    this.http.request('POST', url).subscribe((res: any) => {
      if (res.status === 500) {
        this.toast.info(this.translocoService.instant('Components.CallCenter.WaitingClientsNotFound'));
      }
    });
  }

  muteCurrentCall(): void {
    const holdUrl = environment.apiUrl + '/call-center/api/v1/hold/' + this.callDetails?.data?.call_id;

    this.http.request('POST', holdUrl).subscribe((res: any) => {
      this.isCallMute = res.status == 204;
    });
  }

  unMuteCurrentCall(): void {
    const stopHoldUrl = environment.apiUrl + '/call-center/api/v1/stop-hold/' + this.callDetails?.data?.call_id;

    this.http.request('DELETE', stopHoldUrl).subscribe((res: any) => {
      this.isCallMute = res.status != 200;
    });
  }

  openForwardCallModal(): void {
    this.isDisabledBtn.set(true);
    this.modalService.open({
      component: ForwardCallComponent,
      title: 'Actions.ForwardCall',
      componentOptions: {
        callId: this.callDetails.data.call_id,
      }
    }).then((res) => {
      this.isDisabledBtn.set(false);
    }).catch((err) => {
      this.isDisabledBtn.set(false);
    });
  }

  openSelectorModal() {
    this.outgoingModalBtn = true;
    this.modalService.open({
      component: OutgoingCallComponent,
      title: this.translocoService.instant('Components.CallCenter.OutgoingCalls'),
      modalOptions: {
        modalDialogClass: 'outgoing-call-modal',
      },
    }).then((result) => {
      this.outgoingModalBtn = false;
    }).catch((err) => {
      this.outgoingModalBtn = false;
    });
  }

  arraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }
    return true;
  }

  trackById(index: number, item: any): any {
    return item.id;
  }

  isEndCall() {
    this.isDisabledBtn.set(true);
    this._confirmModalService
      .openConfirmationDialog(
        'EndCall',
        'SureEndCall',
        'Termination',
        'danger',
        'Actions.Toast.CancelButton'
      )
      .then((res) => {
        this.isDisabledBtn.set(false);
        if (res) {
          const url = environment.apiUrl + '/call-center/api/v1/hungup-call';
          const body = {
            call_id: this.callDetails?.data?.call_id
          };
          this.http.post(url, body).subscribe({
            next: (res) => {
              if (res && res['success']) {
                this.currentCallDetailsService.removeData();
                setTimeout(() => {
                  this.toast.info(this.translocoService.instant('CallEnd'));
                }, 500);
              }
            },
            error: (err) => {
              console.log(err);
            }
          });
          //   this._defaultService.post(this.myFormData.id).subscribe({
          //     next: (value) => {
          //       this.getIncidents();
          //       this.form.reset();
          //       this.myFormData = undefined;
          //       this._toastService.success(
          //         "Actions.DeleteIncidentsMessage"
          //       );
          //     },
          //   });
        }
      }).catch((err) => {
      this.isDisabledBtn.set(false);
    });
  }


  // callTimer counter
  updateTimer() {
    if (!this.callDetails || !this.callDetails.startTime) {
      return;
    } else {
      const now = DateTime.now();
      const diff = now.diff(DateTime.fromISO(this.callDetails?.startTime), ['hours', 'minutes', 'seconds']);

      this.isCallTime.set(diff.toFormat('hh:mm:ss'));
    }
  }
}
