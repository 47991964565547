import 'hammerjs';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, Injectable, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {RouterModule, RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {CoreModule} from 'core/core.module';
import {coreConfig} from './app.config';
import {AppComponent} from 'app/app.component';
import {APP_BASE_HREF} from '@angular/common';
import {catchError, map} from 'rxjs/operators';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BS_BREAKPOINTS, CustomBreakPointsProvider} from '@core/layout/custom-breakpoints';
import {LayoutComponent} from '@core/layout/layout.component';
import {appRoutes} from '@app/app.routing';
import {AuthService} from '@app/modules/pages/login/auth.service';
import {AuthInterceptor} from '@core/interceptor/auth.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from 'environments/environment';
import {OnlineStatusService} from 'ngx-online-status';
import {ChatButtonComponent} from '@chat-service/components/chat-button/chat-button.component';
import {MeService} from './modules/pages/login/helpers/me.service';
import {of} from 'rxjs';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (this.isDynamicImportError(error)) {
      if (confirm('Yangi versiya mavjud. Sahifani CTRL+SHIFT+R kombinatsiyasi orqali yangilang.')) {
        this.forceReload();
      }
    } else {
      throw error;
    }
  }

  private isDynamicImportError(error: any): boolean {
    return error.message.includes('Failed to fetch dynamically imported module');
  }

  private async forceReload(): Promise<void> {
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }
    }

    if ('caches' in window) {
      const cacheNames = await caches.keys();
      for (const cacheName of cacheNames) {
        await caches.delete(cacheName);
      }
    }

    window.stop();
    const url = new URL(window.location.href);
    url.searchParams.set('v', new Date().getTime().toString());

    window.location.replace(url.toString());
  }

}



@Injectable({providedIn: 'root'})
export class CustomTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    public translateService: TranslateService,
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      const key = `PageTitle.${title}`;
      this.translateService.get(key).subscribe((value: any) => {
        this.title.setTitle(`${value} - ${coreConfig.app.appTitle}`);
      });
    }
  }
}

export function authFactory(authService: AuthService, meService: MeService) {
  return () => {
    if (authService.isLoggedIn()) {
      return meService.loadMeData().pipe(
        map(() => true),
        catchError(error => {
          return of(false);
        })
      );
    } else {
      return Promise.resolve(true);
    }
  };
}



@NgModule({
  imports: [
    /// don't remove below comment below
    // BlockUIModule.forRoot({
    //   template:BlockComponent
    // }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled'
    }),
    TranslateModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      extendedTimeOut: 5000,
      closeButton: true,
      enableHtml: true,
      maxOpened: 3
    }),
    FlexLayoutModule.withConfig({disableDefaultBps: true}, BS_BREAKPOINTS),
    CoreModule.forRoot(coreConfig),
    LayoutComponent,
    ChatButtonComponent,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {enabled: false})
  ],
  providers: [
    {provide: TitleStrategy, useClass: CustomTitleStrategy},
    {provide: APP_BASE_HREF, useValue: '/'},
    {
      provide: APP_INITIALIZER,
      useFactory: authFactory,
      deps: [AuthService, MeService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: OnlineStatusService,
    },
    CustomBreakPointsProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
