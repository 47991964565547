<!-- don't remove code below   -->

<!-- <block-ui [template]="blockTemplate">
    <app-layout></app-layout>
</block-ui> -->
<!--<ngx-loading-bar></ngx-loading-bar>-->
<app-layout></app-layout>


<!--<router-outlet></router-outlet>-->
